import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../style/Login.css";
import logo_image from "../imgs/logo.png";
import InputField from "./basic/InputField";
import ButtonComponent from "./basic/ButtonComponent";
import { loginService } from "../backend/UserService";
import { useHistory } from "react-router-dom";
import { getPassword, getUserName } from "../backend/RESTUtils";
import { sha256 } from "js-sha256";

import { UserContext } from "../context/UserContext";
const Login = () => {
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { initLoginSession } = useContext(UserContext);

  //go to interventii if user is logged in
  useEffect(() => {
    try {
      if (getUserName() && getPassword()) {
        redirectIfCredentialsAreValid();
      }
    } catch (err) {
      if (err && err.response && err.response.status) setError("Invalid Credentials!");
      else setError("Unexpected error!");
    }
  }, []);

  const redirectIfCredentialsAreValid = async () => {
    try {
      await initLoginSession();
      history.push("/interventii");
    } catch (err) {
      if (err && err.response && err.response.status) setError(err.response.data.message);
      else setError("Unexpected error!");
    }
  };

  const handleLogin = async () => {
    try {
      const encryptedPassword = sha256(password);
      const result = await loginService(userName, encryptedPassword);
      localStorage.setItem("userName", result.userRoleDTO.userName);
      localStorage.setItem("userId", result.userRoleDTO.id);
      localStorage.setItem("password", encryptedPassword);
      initLoginSession();
      setError("");
      history.push("/interventii");
    } catch (err) {
      if (err && err.response && err.response.status) setError(err.response.data.message);
      else setError("Unexpected error!");
    }
  };
  return (
    <Row className="black_screen" style={{width: "120%"}}>
      <Col>
        <div className="login_wrapper">
          <Image className="login_logo" src={logo_image} />

          {error && (
            <div className="login_error">
              <span>{error}</span>
            </div>
          )}
          <InputField
            label={"Utilizator"}
            value={userName}
            onChange={e => setUserName(e.target.value)}
          />
          <InputField
            type="password"
            label={"Parola"}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          <div className="login_button_wrapper">
            <ButtonComponent
              text={"Login"}
              variant={"primary"}
              onClick={handleLogin}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
