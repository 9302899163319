import React from "react";
import { useState, useContext, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../style/LucrareItem.css";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";
import { getNewMedia } from "../backend/FileUploadService";
import NewMediaItem from "./NewMediaItem";

const NewMediaViewer = ({
}) => {
  const [lucrariFolders, setLucrariFolders] = useState([]);
  const [triggerLucrari, setTriggerLucrari] = useState(false);
  const [activeTab, setActiveTab] = useState("locatie");
  const { internet, serverError } = useContext(RefreshContext);
  const [responseReceived, setResponseReceived] = useState(false);

  const getNewMediaData = async () => {
    try {
      const result = await getNewMedia();
      setLucrariFolders(result);
      setResponseReceived(true);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getNewMediaData();
  }, []);

  return (
    <Row>
      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper">
                <div className="lucrari_cu_fisiere_header">
                  <h2>Imagini noi</h2>
                </div>
              </div>
            </Row>
            <Row className="middleScreen">
              <Col>
                {lucrariFolders.length === 0 && responseReceived === true && (
                  <div className="lucrari_not_found">
                    Nu sunt imagini noi.
                  </div>
                )}

                {lucrariFolders.map(
                  (lucrare, index) => (
                    <NewMediaItem
                      key={index}
                      lucrareFolder={lucrare}
                      locationType={lucrare?.locatie?.domeniu?.name}
                      locationName={lucrare?.locatie?.name}
                      locationFolderName={lucrare?.locatie?.originalName}
                      lucrareFolderName={lucrare?.originalName}
                      locationId={lucrare?.locatie?.id}
                    />
                  )
                )}
              </Col>
            </Row>
          </>
        ) : (
          <div className="no_internet_wrapper">
            <Image src={logo_image} />
            <div className="no_internet_text">
              {serverError
                ? `Nu este conexiune la server`
                : `Nu este conexiune la internet`}
            </div>
          </div>
        )}
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLucrare
        // locationType={
        //   location?.state?.locationType
        // }
        // currentLucrare={currentLucrare}
        // activeTab={activeTab}
        // setTab={setTab}
        // refreshLucrari={refreshLucrari}
        // locatieName={location && location.state && location.state.locatieName}
        // locatieId={location && location.state && location.state.locatieId}
        />
      </Col>
    </Row>
  );
};
export default NewMediaViewer;
