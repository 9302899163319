import React, { useState, useContext, useEffect } from "react";
import ButtonComponent from "../components/basic/ButtonComponent";
import {
  createReminderService
} from "../backend/LucrareService";
import TextAreaComponent from "../components/basic/TextAreaComponent";
import { Row, Col } from "react-bootstrap";
import { RefreshContext } from "../context/RefreshContext";
import DatePicker from "react-datepicker";
import {
  showDateFormatFullYear,
  showTimeFormat,
} from "../utils/Utils";
import { getUserName } from "../backend/RESTUtils";

const ModalReminderCreate = ({
  closeModalReminder,
  closeModalDeDiscutatReminder,
  lucrareId,
  refreshLucrari
}) => {
  const [descriere, setDescriere] = useState("");
  const { refreshNumere, blocking, setBlocking } = useContext(RefreshContext);
  const [reminderDate, setReminderDate] = useState();
  const [initReminderDate, setInitReminderDate] = useState();

  useEffect(() => {
    const fetchInitialValue = async () => {
      const userName = await getUserName();

      if (userName === "hvd" || userName === "hv") {
        const date = new Date();
        date.setHours(15);
        date.setMinutes(45);
        setInitReminderDate(date);
      } else {
        setInitReminderDate(new Date());
      }
    };

    fetchInitialValue();
  }, []);


  const addReminder = async () => {
    try {
      setBlocking(true)
      const reminder = {
        data: reminderDate,
        observatie: descriere
      }
      await createReminderService(reminder, lucrareId);
      refreshNumere();
      refreshLucrari();
      closeModalReminder();
      closeModalDeDiscutatReminder();
    } catch (err) {
      console.error(err);
    }
    finally {
      setBlocking(false)
    }
  };

  return (
    <Row>
      <Col className="text-center">
        <div style={{ marginBottom: "15px" }}>
          <DatePicker
            locale="ro"
            showTimeSelect
            timeFormat="HH:mm"
            className="input_locatie"
            dateFormat="dd/MM/yyyy"
            selected={reminderDate ? reminderDate : initReminderDate}
            minDate={new Date()}
            timeIntervals={5}
            value={
              reminderDate
                ? `${showDateFormatFullYear(
                  reminderDate
                )} ${showTimeFormat(
                  new Date(reminderDate)
                )}`
                : "Data/Ora"
            }
            onChange={(date) => {
              setReminderDate(date);
            }}
          />
        </div>
        <div className="text-center">
          <TextAreaComponent
            placeholder="Observatie"
            rows={3}
            cols={50}
            value={descriere}
            onChange={e => {
              setDescriere(e.target.value);
            }}
            autoFocus
          />
        </div>
        <div className="text-center">
          <ButtonComponent
            text={"Adauga"}
            onClick={addReminder}
            variant={"primary"}
            disabled={blocking}
          />
        </div>
      </Col>
    </Row >
  );
};

export default ModalReminderCreate;
