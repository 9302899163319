import React from "react";
import { Row, Col } from "react-bootstrap";
import { showDateFormat, showTimeFormat } from "../../utils/Utils";

const LocatieLucrareItem = ({ item }) => {
  return (
    <>
      <Row className="locatie_lucrare_wrapper_big">
        <Col>
          <div>
            <div className="rightComponentWrapper">
              <p className={`rightComponentLabel`}>
                {`${showDateFormat(new Date(item.date))} ${showTimeFormat(
                  new Date(item.date)
                )}`}
              </p>

              <div className={"rightComponentValue d-inline-block"}>
                <div>{`${item.nrKm} Km - ${item.combustibil} L - ${item.consum} L/100`}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LocatieLucrareItem;
