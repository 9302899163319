import React from "react";

const TextAreaComponent = ({
  value,
  label,
  onChange,
  id,
  className,
  readOnly,
  defaultValue,
  rows,
  cols,
  placeholder,
  style,
  autoFocus=false
}) => {
  return (
    <>
      {label && <h2>{label}</h2>}
      <textarea
        placeholder={placeholder}
        id={id}
        rows={rows}
        cols={cols}
        className={className}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        readOnly={readOnly}
        style={style}
        autoFocus={autoFocus}
      ></textarea>
    </>
  );
};

export default TextAreaComponent;
