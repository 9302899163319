import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import TextAreaComponent from "../../components/basic/TextAreaComponent";
import ButtonComponent from "../../components/basic/ButtonComponent";
import {
  addObservatieLocatieService,
  updateObservatieLocatieService
} from "../../backend/ObservatieService";
import { RefreshContext } from "../../context/RefreshContext";

const ObservatieLocatie = ({
  locatieId,
  closeModalObservatie,
  setTab,
  initialObservatie,
  
}) => {
  const { refreshObservatiiLocatie } = useContext(RefreshContext);

  const [observatie, setObservatie] = useState();

  const addOrUpdateObservatie = async () => {
    try {
      if (initialObservatie) {
        await updateObservatieLocatieService(observatie);
      } else {
        await addObservatieLocatieService(locatieId, observatie);
        setTab("observatii");
      }
      closeModalObservatie();
      refreshObservatiiLocatie();
      
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (initialObservatie) setObservatie(initialObservatie);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <TextAreaComponent
            placeholder="Observatie detalii"
            value={observatie ? observatie.text : ""}
            onChange={e =>
              setObservatie({ ...observatie, text: e.target.value })
            }
            rows={4}
            cols={55}
            autoFocus
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <ButtonComponent
            text={
              initialObservatie
                ? "Modificare"
                : "Adaugare"
            }
            onClick={addOrUpdateObservatie}
          />
        </Col>
      </Row>
    </>
  );
};

export default ObservatieLocatie;
