import React, { createContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getApplication } from "../backend/RESTUtils";
import { setApplication as setApplicationInStorage } from "../backend/RESTUtils";
export const RefreshContext = createContext();

const RefreshContextProvider = ({ children }) => {
  const [triggerObservatiiLucrare, setTriggerObservatiiLucrare] =
    useState(false);
  const [triggerObservatiiLocatie, setTriggerObservatiiLocatie] =
    useState(false);

  const [triggerNumere, setTriggerNumere] = useState(false);
  const [triggerInterventii, setTriggerInterventii] = useState(false);

  const [triggerCurrentLucrare, setTriggerCurrentLucrare] = useState(false);

  const [internet, setInternet] = useState(false);
  const [serverError, setServerError] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentDomeniu, setCurrentDomeniu] = useState("");
  const [currentApplication, setCurrentApplication] = useState();

  const [iscirFaza1, setIscirFaza1] = useState(false);
  const [reviziiFaza1, setReviziiFaza1] = useState(false);
  const [adminnistrativProgramate, setAdminnistrativProgramate] =
    useState(false);
  const [istoricLocationId, setIstoricLocationId] = useState(null);

  const [blocking, setBlocking] = useState(false);
  const [refreshNewMediaContext, setRefreshNewMediaContext] = useState(false);
  const [triggerLocatieDocuments, setTriggerLocatieDocuments] = useState(false);

  const refreshObservatiiLucrare = () => {
    setTriggerObservatiiLucrare(!triggerObservatiiLucrare);
  };

  const refreshObservatiiLocatie = () => {
    setTriggerObservatiiLocatie(!triggerObservatiiLocatie);
  };

  const refreshNumere = () => {
    setTriggerNumere(!triggerNumere);
  };

  const refreshInterventii = () => {
    setTriggerInterventii(!triggerInterventii);
  };

  const refreshCurrentLucrare = () => {
    setTriggerCurrentLucrare(!triggerCurrentLucrare);
  };

  const triggerRefreshNewMedia = () => {
    setRefreshNewMediaContext(!refreshNewMediaContext);
  }

  const refreshLocatieDocuments = () => {
    setTriggerLocatieDocuments(!triggerLocatieDocuments);
  }

  useEffect(() => {
    setCurrentApplication(getApplication());
  }, []);

  const setApplication = (application) => {
    setCurrentApplication(application);
    setApplicationInStorage(application);
  }

  const isLogicsApp = () => {
    return currentApplication === "Logics";
  }
  
  const isPixiaConturApp = () => {
    return currentApplication === "PixiaContur";
  }
  
  const isTehnicStudioApp = () => {
    return currentApplication === "TehnicStudio";
  }
  

  return (
    <RefreshContext.Provider
      value={{
        triggerNumere,
        triggerInterventii,
        triggerObservatiiLucrare,
        triggerObservatiiLocatie,
        triggerCurrentLucrare,
        refreshNumere,
        refreshInterventii,
        refreshObservatiiLucrare,
        refreshObservatiiLocatie,
        refreshCurrentLucrare,
        internet,
        setInternet,
        serverError,
        setServerError,
        currentUser,
        setCurrentUser,
        currentMonth,
        setCurrentMonth,
        iscirFaza1,
        setIscirFaza1,
        reviziiFaza1,
        setReviziiFaza1,
        blocking,
        setBlocking,
        setAdminnistrativProgramate,
        adminnistrativProgramate,
        istoricLocationId,
        setIstoricLocationId,
        currentDomeniu,
        setCurrentDomeniu,
        currentApplication,
        setApplication,
        isLogicsApp,
        isPixiaConturApp,
        isTehnicStudioApp,
        refreshNewMediaContext,
        triggerRefreshNewMedia,
        triggerLocatieDocuments,
        refreshLocatieDocuments
      }}
    >
      {children}
    </RefreshContext.Provider>
  );
};

export default withRouter(RefreshContextProvider);
