import React, { useState, useEffect, useContext, useRef } from "react";
import { Row, Col, Image } from "react-bootstrap";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import Lucrare from "../lucrare/Lucrare";
import ModalComponent from "./basic/ModalComponent";
import {
  getLucrariByTypeService,
  getLucrariPsiService,
  readLucrariService
} from "../backend/LucrareService";
import LucrareItem from "../lucrare/LucrareItem";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";
import { months } from "../utils/Values";
import { refreshTimer, readLucrariTimer } from "../utils/Values";

const Psi = () => {
  const [showModal, setShowModal] = useState(false);
  const [lucrari, setLucrari] = useState([]);
  const [triggerLucrari, setTriggerLucrari] = useState(false);
  const [currentLucrare, setCurrentLucrare] = useState();
  const [activeTab, setActiveTab] = useState("locatie");
  const [refresh, setRefresh] = useState(false);
  const {
    internet,
    serverError,
    currentMonth,
    setCurrentMonth,
    refreshNumere
  } = useContext(RefreshContext);
  const isReadLucrari = useRef(false);

  const setTab = value => {
    setActiveTab(value);
  };

  const refreshLucrari = () => {
    setTriggerLucrari(!triggerLucrari);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const getLucrari = async () => {
    try {
      const result = await getLucrariByTypeService("psi");
      setLucrari(result);
    } catch (err) {
      console.error(err);
    }
  };

  const getMonthLucrari = async () => {
    try {
      const result = await getLucrariPsiService(currentMonth.value);
      setLucrari(result.lucrareListDTO);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const id = startRefreshTimer();
    return () => {
      clearInterval(id);
    };
  }, [refresh]);

  const startRefreshTimer = () => {
    return setInterval(() => {
      setRefresh(!refresh);
    }, refreshTimer);
  };

  useEffect(() => {
    if (currentMonth) {
      getMonthLucrari();
    } else {
      getLucrari();
    }
  }, [triggerLucrari, refresh, currentMonth]);

  const readLucrari = async () => {
    try {
      if (!isReadLucrari.current) {
        await readLucrariService("psi");
        refreshLucrari();
        refreshNumere();
        isReadLucrari.current = true;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const markLucrariRead = timeOut => {
    return setTimeout(() => {
      readLucrari();
    }, timeOut);
  };

  useEffect(() => {
    const id = markLucrariRead(readLucrariTimer);
    return () => {
      clearInterval(id);
      readLucrari();
    };
  }, []);

  return (
    <Row>
      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper psi_header_wrapper">
                <div
                  className="psi_header d-inline-block"
                  onClick={() => setShowModal(true)}
                >
                  <h2>PSI</h2>
                </div>
                <div className="psi_header_months">
                  {months.map((month, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          if (currentMonth === month) setCurrentMonth(null);
                          else setCurrentMonth(month);
                        }}
                        className={
                          currentMonth === month
                            ? "psi_header_month_selected"
                            : "psi_header_month"
                        }
                      >
                        {month.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Row>
            <Row className="middleScreen" onClick={readLucrari}>
              <Col>
                {lucrari.map((lucrare) => (
                  <LucrareItem
                    key={lucrare.uid}
                    refreshLucrari={refreshLucrari}
                    lucrare={lucrare}
                    currentLucrare={currentLucrare}
                    setCurrentLucrare={setCurrentLucrare}
                    setTab={setTab}
                  />
                ))}
              </Col>
            </Row>
            <ModalComponent
              show={showModal}
              close={closeModal}
              headingText="Lucrare PSI"
              body={
                <Lucrare
                  closeModal={closeModal}
                  locationType="psi"
                  refreshLucrari={refreshLucrari}
                />
              }
            />
          </>
        ) : (
            <div className="no_internet_wrapper">
              <Image src={logo_image} />
              <div className="no_internet_text">
                {serverError
                  ? `Nu este conexiune la server`
                  : `Nu este conexiune la internet`}
              </div>
            </div>
          )}
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLucrare
          locationType="psi"
          currentLucrare={currentLucrare}
          setCurrentLucrare={setCurrentLucrare}
          activeTab={activeTab}
          setTab={setTab}
          refreshLucrari={refreshLucrari}
        />
      </Col>
    </Row>
  );
};

export default Psi;
