import React, { createContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  getUserName,
  removeUserName,
  removeUserId,
  getPassword,
  removePassword
} from "../backend/RESTUtils";
import { loginService } from "../backend/UserService";
export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    const userName = getUserName();
    const password = getPassword();
    if (userName && password) {
      initLoginSession();
    }
  }, []);

  const initLoginSession = async () => {
    try {
      const loggedUser = await loginService(getUserName(), getPassword());
      setUser(loggedUser);
    } catch (err) {
      throw err;
    }
  };

  const handleLogout = () => {
    removeUserName();
    removeUserId();
    removePassword();
    setUser(null);
    window.location = "/";
  };

  const refreshUserData = () => {
    initLoginSession();
  };

  const isDispecer = () => {
    let isUserDispecer =
      user && user.userRoleDTO.roles.find(role => role.name === "dispecer");
    return isUserDispecer ? true : false;
  };

  const isAdmin = () => {
    let isUserAdmin =
      user && user.userRoleDTO.roles.find(role => role.name === "admin");
    return isUserAdmin ? true : false;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        initLoginSession,
        handleLogout,
        refreshUserData,
        isDispecer,
        isAdmin
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default withRouter(UserContextProvider);
