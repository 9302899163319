import React, { useState, useEffect, useContext } from "react";

import { Row, Col, Image } from "react-bootstrap";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import { getFoldersForLocation } from "../backend/FileUploadService";
import { useLocation } from "react-router-dom";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";
import FolderLucrare from "../lucrare/FolderLucrare";

const FolderLocatie = () => {
  const [lucrari, setLucrari] = useState([]);
  const [triggerLucrari, setTriggerLucrari] = useState(false);
  const [activeTab, setActiveTab] = useState("locatie");
  const location = useLocation();
  const { internet, serverError } = useContext(RefreshContext);
  const [responseReceived, setResponseReceived] = useState(false);

  //prop trimise prin history.push
  const { locationType, locatieName, locatieId, currentLucrare } = location.state;
  const setTab = (value) => {
    setActiveTab(value);
  };

  const refreshLucrari = () => {
    setTriggerLucrari(!triggerLucrari);
  };

  const getLucrari = async () => {
    try {
      const result = await getFoldersForLocation(
        locationType,
        locatieName,
        locatieId
      );
      setLucrari(result);
      setResponseReceived(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getLucrari();
  }, []);

  return (
    <Row>
      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper">
                <div className="lucrari_cu_fisiere_header">
                  <h2>Lucrari cu imagini</h2>
                </div>
              </div>
            </Row>
            <Row className="middleScreen">
              <Col>
                {lucrari.length === 0 && responseReceived === true && (
                  <div className="lucrari_not_found">
                    Nu exista lucrari cu imagini in istoricul locatiei selectate
                  </div>
                )}

                {lucrari.map(
                  (lucrare, index) => (
                    <FolderLucrare
                      key={index}
                      lucrare={lucrare}
                      currentLucrare={currentLucrare}
                      locationType={locationType}
                      locationName={locatieName}
                      locationFolderName={`${locatieName}_${locatieId}`}
                      lucrareFolderName={lucrare.originalName}
                      locationId={locatieId}
                      refreshAction={getLucrari}
                    />
                  )
                )}
              </Col>
            </Row>
          </>
        ) : (
          <div className="no_internet_wrapper">
            <Image src={logo_image} />
            <div className="no_internet_text">
              {serverError
                ? `Nu este conexiune la server`
                : `Nu este conexiune la internet`}
            </div>
          </div>
        )}
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLucrare
          locationType={
            location?.state?.locationType
          }
          currentLucrare={currentLucrare}
          activeTab={activeTab}
          setTab={setTab}
          refreshLucrari={refreshLucrari}
          locatieName={location && location.state && location.state.locatieName}
          locatieId={location && location.state && location.state.locatieId}
        />
      </Col>
    </Row>
  );
};
export default FolderLocatie;
