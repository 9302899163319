import { doGet, doPost } from "./RESTUtils";

export const getLocationsService = async () => {
  return await doGet(`/Logics/locatie/list`);
};

export const addLocationService = async (location) => {
  return await doPost(`/Logics/locatie/create`, location);
};

export const deleteLocationService = async (id) => {
  return await doGet(`/Logics/locatie/delete/${id}`);
};

export const getLucrariLocatieService = async (id) => {
  return await doGet(`/Logics/locatie/${id}/consumMasina`);
};

export const setActiveService = async (id, active) => {
  return await doGet(`/Logics/locatie/setActiv/${id}/${active}`);
};

export const setRevizieSpecialaService = async (id, revSpeciala) => {
  return await doGet(`/Logics/locatie/setRevSpec/${id}/${revSpeciala}`);
};

export const editCifruLocationService = async (id, location) => {
  return await doPost(`/Logics/locatie/editCifru/${id}`, location);
};

export const getLocationService = async (id) => {
  return await doGet(`/Logics/locatie/get/${id}`);
};

export const editLocationService = async (id, location) => {
  return await doPost(`/Logics/locatie/edit/${id}`, {
    ...location,
    instHid: location.instHid ? location.instHid : null,
    instSt: location.instSt ? location.instSt : null,
  });
};

export const getLocationByTypeService = async (type) => {
  return await doGet(`/Logics/locatie/listType/${type}`);
};

export const updateLocationTemporar = async (id, temporar) => {
  return await doGet(`/Logics/locatie/setTemporar/${id}/${temporar}`);
};

export const getZoneService = async () => {
  return await doGet(`/Logics/zona/list`);
};

export const deleteZonaService = async (id) => {
  return await doGet(`/Logics/zona/delete/${id}`);
};

export const createZonaService = async (nume) => {
  return await doPost(`/Logics/zona/create`, { nume });
};
