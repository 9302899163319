import React from "react";
import { FormControl } from "react-bootstrap";
import "../../style/InputField.css";
const InputField = ({ value, onChange, disabled, label, type = "text" }) => {
  return (
    <div className="inputField_wrapper">
      {label && <h4 className="inputField_label">{label}</h4>}

      <FormControl
        type={type}
        className="inputField_input"
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default InputField;
