import React, { useState, useEffect, useContext } from "react";
import { getObservatiiLocatieService } from "../backend/ObservatieService";
import ObservatieLocatieItem from "../locations/observatie/ObservatieLocatieItem";
import { RefreshContext } from "../context/RefreshContext";

const ObservatiiLocatie = ({ locatieId }) => {
  const { triggerObservatiiLocatie } = useContext(RefreshContext);
  const [observatii, setObservatii] = useState([]);

  const getObservatii = async () => {
    try {
      const result = await getObservatiiLocatieService(locatieId);
      setObservatii(result);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getObservatii();
  }, [triggerObservatiiLocatie]);

  return (
    <div className="mt-2 observatii_wrapper">
      {observatii.length > 0 ? (
        <>
          {observatii.map((observatie, index) => (
            <ObservatieLocatieItem key={index} observatie={observatie} />
          ))}
        </>
      ) : (
        <div className="noObservatii">Nu exista observatii</div>
      )}
    </div>
  );
};

export default ObservatiiLocatie;
