import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import {
    getLocationByTypeService,
} from "../backend/LocationService";
import ButtonComponent from "../components/basic/ButtonComponent";
import {
    realizareLucrareProgramataService
} from "../backend/LucrareService";
import { RefreshContext } from "../context/RefreshContext";

import "react-datepicker/dist/react-datepicker.css";
const ProgramateModalFinalizare = ({
    closeModalProgramateFinalizare,
    currentLucrare,
    refreshLucrari,
    closeModal,
}) => {
    const [isRed, setIsRed] = useState(false);
    const [error, setError] = useState("");
    const { refreshNumere, blocking, setBlocking } = useContext(RefreshContext);
    const [lucrare, setLucrare] = useState();
    const [locations, setLocation] = useState([]);

    const getLocations = async () => {
        try {
            const result = await getLocationByTypeService("comenzi");

            setLocation(
                result.map((location) => {
                    return {
                        value: location.id,
                        label: location.locatie,
                        temporar: location.temporar,
                        type: location.type,
                    };
                })
            );
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        setLucrare({
            ...lucrare,
            descriere: currentLucrare.descriere,
            uid: currentLucrare.uid,
            repetitie: currentLucrare.repetitie,
            scadenta: currentLucrare.scadenta,
            locatie: currentLucrare.locatieDTO
                ? {
                    value: currentLucrare.locatieDTO.id,
                    label: currentLucrare.locatieDTO.locatie,
                    temporar: currentLucrare.locatieDTO.temporar,
                }
                : null,
        });
        getLocations();
    }, []);

    const getBackgroundColorLocatie = (type) => {
        switch (type) {
            case "interventii":
                return "#b0b0b0";
            case "ascensoare":
                return "#9ec1e0";
            case "interfoane":
                return "#a6d8b9";
            case "psi":
                return "#f1b2ac";
            case "altele":
                return "#ffcc66";
            case "sediu":
                return "#ffe7b3";
            case "diverse":
                return "#ffe7b3";
            default:
                return "#fffff5";
        }
    };

    const customStyles = {
        singleValue: (provided, state) => {
            const color =
                isNaN(lucrare.locatie.value) || lucrare.locatie.temporar || isRed
                    ? "#c60800"
                    : "black";

            return { color };
        },
        option: (provided, state) => {
            const color = state.data.temporar ? "#c60800" : "black";
            return {
                color,
                backgroundColor: getBackgroundColorLocatie(state.data.type),
                fontSize: 18,
                paddingBottom: "5px",
                WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",

                active: { backgroundColor: "green" },
            };
        },
        menu: (provided, state) => {
            const display = state.options.length === 0 ? "none" : "block";
            return {
                display,
                boxSizing: "border-box",
                position: "absolute",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "10",
            };
        },
    };


    const realizare = async () => {
        try {
            setBlocking(true);

            await realizareLucrareProgramataService(lucrare, lucrare.uid);
            closeModalProgramateFinalizare();
            closeModal();
            refreshLucrari();

        } catch (err) {
            console.error(err);
            setError(err?.response?.data?.message);
        } finally {
            setBlocking(false);
        }
    };

    return (
        <>
            <>{error && (<h3 style={{ color: `#c60800` }}>{error}</h3>)}</>
            <>{!error && (<div>
                <Row>
                    <Col className="text-center mt-3">
                        <ButtonComponent
                            variant="success"
                            text="OK"
                            onClick={realizare}
                            disabled={blocking}
                        />
                    </Col>
                </Row></div>)
            }</>
        </>
    );
};

export default ProgramateModalFinalizare;
