import React from "react";
import { FormControl } from "react-bootstrap";

const InputComponent = ({ value, onChange, disabled, label, placeholder }) => {
  return (
    <div>
      {label && <h6>{label}</h6>}

      <FormControl value={value} onChange={onChange} disabled={disabled} placeholder={placeholder} />
    </div>
  );
};

export default InputComponent;
