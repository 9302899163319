import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Image } from "react-bootstrap";
import {
  getLucrariByTypeService,
  getLucrariReviziiFza1Service
} from "../backend/LucrareService";
import LucrareItem from "../lucrare/LucrareItem";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";
import { refreshTimer } from "../utils/Values";

const Revizii = () => {
  const [lucrari, setLucrari] = useState([]);
  const [triggerLucrari, setTriggerLucrari] = useState(false);
  const [currentLucrare, setCurrentLucrare] = useState();
  const [activeTab, setActiveTab] = useState("locatie");
  const [refresh, setRefresh] = useState(false);
  const { internet, serverError, reviziiFaza1, setReviziiFaza1 } = useContext(
    RefreshContext
  );

  const setTab = value => {
    setActiveTab(value);
  };

  const refreshLucrari = () => {
    setTriggerLucrari(!triggerLucrari);
  };

  const getLucrari = async () => {
    try {
      const result = await getLucrariByTypeService("revizii");
      setLucrari(result);
    } catch (err) {
      console.error(err);
    }
  };

  const getLucrariFaza1 = async () => {
    try {
      const result = await getLucrariReviziiFza1Service();
      setLucrari(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const id = startRefreshTimer();
    return () => {
      clearInterval(id);
    };
  }, [refresh]);

  const startRefreshTimer = () => {
    return setInterval(() => {
      setRefresh(!refresh);
    }, refreshTimer);
  };

  useEffect(() => {
    if (!reviziiFaza1) {
      getLucrari();
    } else {
      getLucrariFaza1();
    }
  }, [triggerLucrari, refresh, reviziiFaza1]);

  return (
    <Row>
      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper psi_header_wrapper">
                <div className="revizii_header">
                  <h2>Revizii</h2>
                </div>
                <div className="psi_header_months">
                  <div
                    onClick={() => {
                      setReviziiFaza1(!reviziiFaza1);
                    }}
                    className={
                      reviziiFaza1
                        ? "iscir_header_button_selected"
                        : "iscir_header_button"
                    }
                  >
                    REGEN
                  </div>
                </div>
              </div>
            </Row>
            <Row className="middleScreen">
              <Col>
                {lucrari.map((lucrare) => (
                  <LucrareItem
                    key={lucrare.uid}
                    refreshLucrari={refreshLucrari}
                    lucrare={lucrare}
                    currentLucrare={currentLucrare}
                    setCurrentLucrare={setCurrentLucrare}
                    setTab={setTab}
                  />
                ))}
              </Col>
            </Row>
          </>
        ) : (
          <div className="no_internet_wrapper">
            <Image src={logo_image} />
            <div className="no_internet_text">
              {serverError
                ? `Nu este conexiune la server`
                : `Nu este conexiune la internet`}
            </div>
          </div>
        )}
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLucrare
          locationType="revizii"
          currentLucrare={currentLucrare}
          setCurrentLucrare={setCurrentLucrare}
          activeTab={activeTab}
          setTab={setTab}
          refreshLucrari={refreshLucrari}
        />
      </Col>
    </Row>
  );
};

export default Revizii;
