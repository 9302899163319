import Axios from "axios";

export const BASE_URL = "http://81.196.102.78:8411";
//export const BASE_URL = "http://localhost:8080";
export const http = Axios.create({
  withCredentials: true,
  timeout: 60000,
  params: {},
});

export const getUserName = () => {
  return localStorage.getItem("userName");
};

export const getPassword = () => {
  return localStorage.getItem("password");
}

export const getUserId = () => {
  return localStorage.getItem("userId");
};
export const removeUserName = () => {
  return localStorage.removeItem("userName");
};

export const removePassword = () => {
  return localStorage.removeItem("password");
}

export const removeUserId = () => {
  return localStorage.removeItem("userId ");
};

export const getApplication = () => {
  return localStorage.getItem("application") || "Logics";
}

export const setApplication = (application) => {
  localStorage.setItem("application", application);
}

http.interceptors.request.use(
  async (config) => {
    const userName = getUserName();
    const password = getPassword();
    if (userName) {
      config.headers.Authorization = userName;
    }
    if (password) {
      config.headers.Password = password;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const doPost = async (url, data) => {
  try {
    const result = await http.post(BASE_URL + url, data);
    if (result) return result.data;
    return null;
  } catch (error) {
    throw error;
  }
};

export const doPostWithFormDataWithTimeout = async (url, formData) => {
  try {
    const result = await http.post(BASE_URL + url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 25000
    });

    if (result) return result.data;
    return null;
  } catch (error) {
    console.log("ERROR", error);
    throw error;
  }
};

export const doGet = async (url, config) => {
  try {
    const result = await http.get(BASE_URL + url, config);
    if (result) return result.data;
    return null;
  } catch (error) {
    throw error;
  }
};

export const doPut = async (url, body, config) => {
  try {
    const result = await http.put(BASE_URL + url, body, config);
    if (result) return result.data;
    return null;
  } catch (error) {
    throw error;
  }
};

export const doPostWithConfig = async (url, data, config) => {
  try {
    const result = await http.post(BASE_URL + url, data, config);
    return result;
  } catch (error) {
    throw error;
  }
};