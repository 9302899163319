import React, { useState, useEffect } from "react";
import { getIstoricService } from "../backend/LucrareService";
import { showDateFormat, showTimeFormat } from "../utils/Utils";
import { Row, Col } from "react-bootstrap";

const IstoricLucrare = ({ currentLucrare }) => {
  const [istoric, setIstoric] = useState([]);
  const getIstoric = async () => {
    try {
      const result = await getIstoricService(currentLucrare.uid);

      setIstoric(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getIstoric();
  }, [currentLucrare]);

  const getText = (action, istoric) => {
    switch (action) {
      case null:
        return `${showDateFormat(
          new Date(istoric.actionDate)
        )} ${showTimeFormat(
          new Date(istoric.actionDate)
        )} ${istoric.username ? istoric.username : ""} ${(() => {
          if (istoric.faza === 1) {
            return 'Faza 1: In asteptare.';
          } else if (istoric.faza === 2) {
            return 'Faza 2: In asteptare cu prioritate.';
          } else if (istoric.faza === 3) {
            return 'Faza 3: In lucru.';
          } else if (istoric.faza === 4) {
            return 'Faza 4: In lucru cu prioritate.';
          } else if (istoric.faza === 8) {
            return 'a incheiat lucrarea.'
          }
          else {
            return 'Lucrarea a trecut in faza ' + istoric.faza;
          }
        })()}`;
      case "INTRAT":
        return `${showDateFormat(
          new Date(istoric.actionDate)
        )} ${showTimeFormat(new Date(istoric.actionDate))} ${
          istoric.username
        } participa la lucrare.`;
      case "REALIZAT":
        return `${showDateFormat(
          new Date(istoric.actionDate)
        )} ${showTimeFormat(new Date(istoric.actionDate))} ${
          istoric.username
        } a realizat lucrarea.`;
      case "PARTIAL":
        return `${showDateFormat(
          new Date(istoric.actionDate)
        )} ${showTimeFormat(new Date(istoric.actionDate))} ${
          istoric.username
        } partial.`;
      case "IESIT":
        return `${showDateFormat(
          new Date(istoric.actionDate)
        )} ${showTimeFormat(new Date(istoric.actionDate))} ${
          istoric.username
        } partial.`;
    }
  };
  return (
    <div className="tab_wrapper_detalii">
      <hr className="solid" />
      {istoric.map((istoric, index) => (
        <Row key={index} className="istoric_item">
          <Col>{getText(istoric.action, istoric)}</Col>
        </Row>
      ))}
    </div>
  );
};

export default IstoricLucrare;
