import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import logo_image from "../imgs/logo.png";
import about_pixia from "../imgs/about_pixia.png";
import about_tehnic_studio from "../imgs/about_tehnic_studio.png";

const About = () => {
  const location = useLocation();
  useEffect(() => {
    console.log(location?.state?.applicationName);
  }, []);
  return (
    <Row>
      <Col md={12} style={{display: "flex", justifyContent: "center"}}>
        <div style={{ display: "flex", justifyContent: "center", width: "70%", height: "100%", alignSelf: "center" }}>
          {(location?.state?.applicationName === "PixiaContur") && <Image style={{ width: "100%", height: "100%" }} src={about_pixia} />}
          {(location?.state?.applicationName === "TehnicStudio") && <Image style={{ width: "100%", height: "100%" }} src={about_tehnic_studio} />}
        </div>
      </Col>
    </Row>
  );
};

export default About;
