import { doGet, doPost, } from "./RESTUtils";

export const addLucrareService = async (lucrare, locatieId) => {
  return await doPost(`/Logics/lucrare/create/${locatieId}`, {
    descriere: lucrare.descriere,
    faza: lucrare.faza ? lucrare.faza : 1,
    isInterventie: lucrare.isInterventie,
    lucrareType: lucrare.lucrareType,
    atribuiti: lucrare.atribuiti
      ? lucrare.atribuiti.map((atr) => atr.toLowerCase())
      : null,
    dataTermen: lucrare.dataTermen,
    tipSediu: lucrare.tipSediu,
  });
};

export const addLucrareProgramataService = async (lucrare) => {
  return await doPost(`/Logics/lucrare/programate/create/`, {
    descriere: lucrare.descriere,
    lucrareType: "administrativ",
  });
};


export const addLucrareProgramataImportantaService = async (lucrare) => {
  return await doPost(`/Logics/lucrare/programate/create?important=true`, {
    descriere: lucrare.descriere,
    lucrareType: "administrativ",
  });
};

export const activareLucrareProgramataService = async (lucrare, lucrareId) => {
  return await doPost(`/Logics/lucrare/programate/update/${lucrareId}`, {
    descriere: lucrare.descriere,
    faza: 6,
    scadenta: lucrare.scadenta,
    repetitie: lucrare.repetitie,
    lucrareType: "administrativ",
  });
};

export const realizareLucrareProgramataService = async (lucrare, lucrareId) => {
  return await doGet(`/Logics/lucrare/programate/finalizare/${lucrareId}`);
};

export const addLucrareComenziService = async (lucrare) => {
  return await doPost(`/Logics/lucrare/create`, {
    descriere: lucrare.descriere,
    faza: lucrare.faza ? lucrare.faza : 1,
    isInterventie: lucrare.isInterventie,
    lucrareType: lucrare.lucrareType,
    atribuiti: lucrare.atribuiti
      ? lucrare.atribuiti.map((atr) => atr.toLowerCase())
      : null,
    dataTermen: lucrare.dataTermen,
  });
};

export const getLucrariProgramateService = async () => {
  return await doGet(`/Logics/lucrare/programate/list`);
};

export const getLucrariProgramateImportanteService = async () => {
  return await doGet(`/Logics/lucrare/programate/list?important=true`);
};

export const getLucrariByTypeService = async (type) => {
  return await doGet(`/Logics/lucrare/listType/${type}`);
};

export const getLucrariIscirFza1Service = async () => {
  return await doGet(`/Logics/lucrare/iscirFaza1`);
};

export const getLucrariReviziiFza1Service = async () => {
  return await doGet(`/Logics/lucrare/reviziiFaza1`);
};

export const getLucrariPsiService = async (month) => {
  return await doGet(`/Logics/lucrare/listPsi/${month}`);
};

export const updateLucrarePsiService = async (lucrareId, psi) => {
  return await doPost(`/Logics/lucrare/${lucrareId}/updateTermenePsi`, psi);
};

export const generareLucrarePsiService = async (lucrareId) => {
  return await doGet(`/Logics/lucrare/generatePsi/${lucrareId}`);
};

export const generareLucrareIscirService = async (lucrareId) => {
  return await doGet(`/Logics/lucrare/generateIscir/${lucrareId}`);
};

export const getLucrariFinalizateService = async (
  locatieId,
  pageSize,
  pageNumber
) => {
  return await doGet(
    `/Logics/lucrare/list/${locatieId}?pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
};

export const getLucrariFinalizateAdministrativ = async (
  pageSize,
  pageNumber
) => {
  return await doGet(
    `/Logics/lucrare/listFinalizateAdministrativ?pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
};


export const changeFazaLucrareService = async (lucrareId, stare) => {
  return await doGet(`/Logics/lucrare/schimbaFaza/${lucrareId}/${stare}`);
};

export const getFisaZilnicaService = async (date) => {
  return await doGet(`/Logics/lucrare/list/fisaZilnica/${date}`);
};

export const getFisaZilnicaUserService = async (date, userName) => {
  return await doGet(`/Logics/lucrare/list/fisaZilnica/${date}/${userName}`);
};

export const getFisaZilnicaFirmaService = async (date) => {
  return await doGet(`/Logics/lucrare/list/fisaZilnicaFirma/${date}`);
};

export const getNumbersService = async () => {
  return await doGet(`/Logics/lucrare/contoare`);
};

export const getInterventiiService = async () => {
  return await doGet(`/Logics/lucrare/list/interventie`);
};

export const addFacturaService = async (lucrareId, factura) => {
  return await doPost(`/Logics/lucrare/${lucrareId}/addFactura`, factura);
};

export const updateLucrareDatesService = async (
  lucrareId,
  createDate,
  realizatDate
) => {
  return await doPost(`/Logics/lucrare/update-dates/${lucrareId}`, {
    createDate,
    realizatDate,
  });
};

export const updateLucrareDomeniuService = async (
  lucrareId,
  locatieId,
  lucrareType,
  isInterventie
) => {
  return await doPost(
    `/Logics/lucrare/update-domeniu/${lucrareId}/${locatieId}`,
    {
      lucrareType,
      isInterventie,
    }
  );
};

export const deleteLucrareService = async (lucrareId) => {
  return await doGet(`/Logics/lucrare/delete/${lucrareId}`);
};

export const getIstoricService = async (lucrareId) => {
  return await doGet(`/Logics/lucrare/${lucrareId}/getIstoricList`);
};

export const getLucrareService = async (lucrareId) => {
  return await doGet(`/Logics/lucrare/get/${lucrareId}`);
};

export const updateLucrareService = async (lucrare, locatieId) => {
  return await doPost(`/Logics/lucrare/${locatieId}/changeLocatie`, {
    faza: lucrare.faza,
    descriere: lucrare.descriere,
    atribuiti: lucrare.atribuiti
      ? lucrare.atribuiti.map((atr) => atr.toLowerCase())
      : null,
    dataTermen: lucrare.dataTermen,
    nrKm: lucrare.nrKm,
    combustibil: lucrare.combustibil,
    uid: lucrare.uid,
    repetitie: lucrare.repetitie,
    scadenta: lucrare.scadenta
  });
};

export const addDeDiscutatService = async (deDiscutat, lucrareId) => {
  return await doPost(
    `/Logics/lucrare/discutie/create/${lucrareId}`,
    deDiscutat
  );
};

export const getLucrariAdministrativNotificariService = async () => {
  return await doGet(`/Logics/lucrare/discutie/get`);
};

export const confirmDiscutieService = async (discutieId) => {
  return await doGet(`/Logics/lucrare/discutie/delete/${discutieId}`);
};

export const deleteLucrareAdministrativService = async (lucrareId) => {
  return await doGet(`/Logics/lucrare/delete/administrativ/${lucrareId}`);
};

export const getDiscutieService = async (discutieId) => {
  return await doGet(`/Logics/lucrare/discutie/get/${discutieId}`);
};

export const updateDiscutieService = async (discutie, discutieId) => {
  return await doPost(
    `/Logics/lucrare/discutie/update/${discutieId}`,
    discutie
  );
};

export const readLucrariService = async (type) => {
  return await doGet(`/Logics/lucrare/vazut/${type}`);
};

export const getRemindersService = async () => {
  return await doGet(`/Logics/lucrare/reminder/get`);
};

export const deleteReminderService = async (reminderId) => {
  return await doGet(`/Logics/lucrare/reminder/delete/${reminderId}`);
};

export const createReminderService = async(reminder, lucrareId) => {
  return await doPost(
    `/Logics/lucrare/reminder/create/${lucrareId}`,
    reminder
  );
}