import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ButtonComponent from "../components/basic/ButtonComponent";
import {
  addLocationService,
  getLocationService,
  editLocationService,
  updateLocationTemporar,
  getZoneService,
  createZonaService,
} from "../backend/LocationService";
import CreatableSelect from "react-select/creatable";
import { months } from "../utils/Values";
import { mapMonth, isLocatieCar, showDateFormatFullYear } from "../utils/Utils";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ro from 'date-fns/locale/ro';

const Location = ({
  closeModal,
  refreshLocations,
  locationId,
  locationType,
  addFromClient = false,
  locations,
}) => {
  const [location, setLocation] = useState();
  const [zone, setZone] = useState([]);
  const [error, setError] = useState("");
  const [isRed, setIsRed] = useState(false);
  const [faraScadentaIscir, setFaraScadentaIscir] = useState(false);
  const [blocking, setBlocking] = useState(false);

  const generateOptionsForIscirYear = () => {
    let date = new Date();
    let year = date.getFullYear();

    let options = [];
    for (let i = year; i <= year + 4; i++) {
      options.push({ value: i, label: `${i}` });
    }
    return options;
  };

  useEffect(() => {
    registerLocale('ro', ro);
  });

  const getLocation = async () => {
    try {
      const result = await getLocationService(locationId);
      if (!result.terIscir) setFaraScadentaIscir(true);

      if (addFromClient) {
        setLocation({
          ...location,
          id: result.id,
          temporar: result.temporar,
          client: result.client,
          descriere: result.descriere,
          adresa: result.adresa,
          persoana: result.persoana,
          tel: result.tel,
          email: result.email,
          contract: result.contract,
          scadenta: result.scadenta,
          cui: result.cui,
          firma: result.firma,
          instHid: result.instHid,
          instSt: result.instSt,
          itp: result.itp,
          rca: result.rca,
          parcare: result.parcare,
          rovigneta: result.rovigneta,
          terIscir: result.terIscir,
          terHid: result.terHid
            ? { value: result.terHid, label: mapMonth(result.terHid) }
            : null,
          terSt: result.terSt
            ? { value: result.terSt, label: mapMonth(result.terSt) }
            : null,
          zona: result.zona ? { value: result.zona, label: result.zona } : null,
        });
      } else {
        setLocation({
          ...location,
          id: result.id,
          temporar: result.temporar,
          locatie: result.locatie,
          client: result.client,
          descriere: result.descriere,
          adresa: result.adresa,
          persoana: result.persoana,
          tel: result.tel,
          email: result.email,
          contract: result.contract,
          scadenta: result.scadenta,
          cui: result.cui,
          firma: result.firma,
          instHid: result.instHid,
          itp: result.itp,
          rca: result.rca,
          parcare: result.parcare,
          rovigneta: result.rovigneta,
          instSt: result.instSt,
          terIscir: result.terIscir,
          terHid: result.terHid
            ? { value: result.terHid, label: mapMonth(result.terHid) }
            : null,
          terSt: result.terSt
            ? { value: result.terSt, label: mapMonth(result.terSt) }
            : null,
          zona: result.zona ? { value: result.zona, label: result.zona } : null,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isLocationInDatabase = () => {
    if (location && location.id) {
      const findLocation = locations.find(
        (l) => l.locatie.toLowerCase() === location.locatie.toLowerCase()
      );

      if (!findLocation) return false;
      if (findLocation.value === location.id) return false;
      else return true;
    } else {
      const filterLocations = locations.filter(
        (l) => l.locatie.toLowerCase() === location.locatie.toLowerCase()
      );
      return filterLocations.length > 0;
    }
  };

  const addLocation = async () => {
    try {
      setBlocking(true);
      if (location && location.locatie) {
        if (!isLocationInDatabase()) {
          if (addFromClient) {
            await addLocationService({ ...location, type: locationType });
          } else {
            if (locationId) {
              await editLocationService(locationId, {
                ...location,
                type: locationType,
              });
            } else {
              await addLocationService({ ...location, type: locationType });
            }
          }
          closeModal();
          refreshLocations();
        } else {
          setError("locatie_already_exists");
          clearError();
        }
      } else {
        setError("locatie");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setBlocking(false);
    }
  };

  const clearError = () => {
    setTimeout(() => {
      setError("");
      if (!location.id) {
        setLocation(null);
      } else {
        getLocation();
      }
    }, 4000);
  };

  const addLocationIscir = async () => {
    try {
      console.log("ADADADAD");

      setBlocking(true);
      if (location && location.locatie) {
        if (!isLocationInDatabase()) {
          if (
            faraScadentaIscir ||
            (!faraScadentaIscir && location.terIscir)
          ) {
            let typeLocation = "";
            if (locationType === "iscir" || locationType === "revizii") {
              typeLocation = "ascensoare";
            } else {
              typeLocation = locationType;
            }
            let sendLocation = {
              ...location,
              type: typeLocation,
              terIscir: location.terIscir,
            };
            if (addFromClient) {
              await addLocationService(sendLocation);
            } else {
              if (locationId) {
                await editLocationService(locationId, sendLocation);
              } else {
                await addLocationService(sendLocation);
              }
            }
            closeModal();
            refreshLocations();
          } else {
            if (
              !(location && location.terIscir)
            )
              setError("scadentaIscir");
          }
        } else {
          setError("locatie_already_exists");
          clearError();
        }
      } else {
        setError("locatie");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setBlocking(false);
    }
  };

  const addLocationPsi = async () => {
    try {
      setBlocking(true);
      if (location && location.locatie) {
        if (!isLocationInDatabase()) {
          if (location && location.zona) {
            if (!(location && location.instHid && !location.terHid)) {
              if (!(location && location.instSt && !location.terSt)) {
                if (location.zona && isRed) {
                  //create new zona
                  const result = await createZonaService(location.zona.value);
                  if (addFromClient) {
                    await addLocationService({
                      ...location,
                      zona: result.nume,
                      terHid: location.terHid ? location.terHid.value : null,
                      terSt: location.terSt ? location.terSt.value : null,
                      instHid: location?.instHid ? location?.instHid : null,
                      instSt: location?.instSt ? location?.instSt : null,
                      type: locationType,
                    });
                  } else {
                    if (locationId) {
                      await editLocationService(locationId, {
                        ...location,
                        zona: result.nume,
                        terHid: location.terHid ? location.terHid.value : null,
                        terSt: location.terSt ? location.terSt.value : null,
                        instHid: location?.instHid ? location?.instHid : null,
                        instSt: location?.instSt ? location?.instSt : null,
                        type: locationType,
                      });
                    } else {
                      await addLocationService({
                        ...location,
                        zona: result.nume,
                        terHid: location.terHid ? location.terHid.value : null,
                        terSt: location.terSt ? location.terSt.value : null,
                        instHid: location?.instHid ? location?.instHid : null,
                        instSt: location?.instSt ? location?.instSt : null,
                        type: locationType,
                      });
                    }
                  }
                  closeModal();
                  refreshLocations();
                } else {
                  if (addFromClient) {
                    await addLocationService({
                      ...location,
                      zona: location.zona.label,
                      terHid: location.terHid ? location.terHid.value : null,
                      terSt: location.terSt ? location.terSt.value : null,
                      instHid: location?.instHid ? location?.instHid : null,
                      instSt: location?.instSt ? location?.instSt : null,
                      type: locationType,
                    });
                  } else {
                    if (locationId) {
                      await editLocationService(locationId, {
                        ...location,
                        zona: location.zona.label,
                        terHid: location.terHid ? location.terHid.value : null,
                        terSt: location.terSt ? location.terSt.value : null,
                        instHid: location?.instHid ? location?.instHid : null,
                        instSt: location?.instSt ? location?.instSt : null,
                        type: locationType,
                      });
                    } else {
                      await addLocationService({
                        ...location,
                        type: locationType,
                        zona: location.zona.label,
                        terHid: location.terHid ? location.terHid.value : null,
                        terSt: location.terSt ? location.terSt.value : null,
                        instHid: location?.instHid ? location?.instHid : null,
                        instSt: location?.instSt ? location?.instSt : null,
                      });
                    }
                  }
                  closeModal();
                  refreshLocations();
                }
              } else {
                setError("stingatoare");
              }
            } else {
              setError("hidranti");
            }
          } else {
            setError("zona");
          }
        } else {
          setError("locatie_already_exists");
          clearError();
        }
      } else {
        setError("locatie");
      }
    } catch (err) {
      console.log(err?.response);
    } finally {
      setBlocking(false);
    }
  };

  const preluareLocation = async () => {
    try {
      setBlocking(true);
      if (location && location.locatie) {
        if (!isLocationInDatabase()) {
          await editLocationService(locationId, {
            ...location,
            type: locationType,
          });
          await updateLocationTemporar(locationId, false);
          closeModal();
          refreshLocations();
        } else {
          setError("locatie_already_exists");
          clearError();
        }
      } else {
        setError("locatie");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setBlocking(false);
    }
  };

  const preluareLocationIscir = async () => {
    try {
      setBlocking(true);
      if (location && location.locatie) {
        if (!isLocationInDatabase()) {
          if (
            faraScadentaIscir ||
            (!faraScadentaIscir && location.terIscir)
          ) {
            let typeLocation = "";
            if (locationType === "iscir" || locationType === "revizii") {
              typeLocation = "ascensoare";
            } else {
              typeLocation = locationType;
            }
            let sendLocation = {
              ...location,
              type: typeLocation,
              terIscir: location.terIscir
            };
            await editLocationService(locationId, sendLocation);
            await updateLocationTemporar(locationId, false);
            closeModal();
            refreshLocations();
          } else {
            if (!location.terIscir) setError("scadentaIscir");
          }
        } else {
          setError("locatie_already_exists");
          clearError();
        }
      } else {
        setError("locatie");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setBlocking(false);
    }
  };

  const preluareLocationPsi = async () => {
    try {
      setBlocking(true);
      if (location && location.locatie) {
        if (!isLocationInDatabase()) {
          if (location && location.zona) {
            if (!(location && location.instHid && !location.terHid)) {
              if (!(location && location.instSt && !location.terSt)) {
                if (location.zona && isRed) {
                  //create new zona
                  const result = await createZonaService(location.zona.value);
                  await editLocationService(locationId, {
                    ...location,
                    type: locationType,
                    zona: result.nume,
                    terHid: location.terHid ? location.terHid.value : null,
                    terSt: location.terSt ? location.terSt.value : null,
                  });
                  await updateLocationTemporar(locationId, false);
                  closeModal();
                  refreshLocations();
                } else {
                  await editLocationService(locationId, {
                    ...location,
                    type: locationType,
                    zona: location.zona.label,
                    terHid: location.terHid ? location.terHid.value : null,
                    terSt: location.terSt ? location.terSt.value : null,
                  });
                  await updateLocationTemporar(locationId, false);
                  closeModal();
                  refreshLocations();
                }
              } else {
                setError("stingatoare");
              }
            } else {
              setError("hidranti");
            }
          } else {
            setError("zona");
          }
        } else {
          setError("locatie_already_exists");
          clearError();
        }
      } else {
        setError("locatie");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setBlocking(false);
    }
  };

  const getZone = async () => {
    try {
      const result = await getZoneService();
      setZone(
        result.map((zona) => {
          return {
            value: zona.nume,
            label: zona.nume,
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const isSelectedZoneNew = (zoneName) => {
    const zzz = zone.find((zona) => zona.value === zoneName);

    return zzz ? false : true;
  };

  useEffect(() => {
    generateOptionsForIscirYear();
    if (locationId) getLocation();
    if (locationType === "psi") getZone();
  }, []);

  const customStylesZona = {
    singleValue: (provided, state) => {
      const color = isRed ? "#c60800" : "black";
      return { color };
    },
    option: (base, state) => {
      return {
        ...base,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
      };
    },
    control: (base, state) => {
      return {
        ...base,
        border: "1px solid black",
        "&:hover": {
          border: "1px solid black",
        },
      };
    },
  };

  const customStyles = {
    singleValue: (provided, state) => {
      const color = "black";
      return { color };
    },
    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row"
    }),
    option: (base, state) => {
      return {
        ...base,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
      };
    },
    control: (base, state) => {
      return {
        ...base,
        border: "1px solid black",
        "&:hover": {
          border: "1px solid black",
        },
      };
    },
  };

  const addLocationType = () => {
    if (
      locationType !== "psi" &&
      locationType !== "ascensoare" &&
      locationType !== "revizii" &&
      locationType !== "iscir"
    )
      addLocation();

    if (locationType === "psi") addLocationPsi();

    if (
      locationType === "ascensoare" ||
      locationType === "revizii" ||
      locationType === "iscir"
    )
      addLocationIscir();
  };

  const preluareLocationType = () => {
    if (
      locationType !== "psi" &&
      locationType !== "ascensoare" &&
      locationType !== "revizii" &&
      locationType !== "iscir"
    )
      preluareLocation();

    if (locationType === "psi") preluareLocationPsi();

    if (
      locationType === "ascensoare" ||
      locationType === "revizii" ||
      locationType === "iscir"
    )
      preluareLocationIscir();
  };

  return (
    <>
      {error === "locatie_already_exists" && (
        <div className="location_already_exists">
          <h3>Exista locatia in baza de date!</h3>
        </div>
      )}
      <div className="location_inputs">
        <div className="locatieInputWrapper">
          <p>{locationType === "comenzi" ? "Furnizor" : "Locatie"}</p>
          <input
            className={
              error === "locatie" ? "input_locatie_error" : "input_locatie"
            }
            value={location ? location.locatie : ""}
            onChange={(e) => {
              setLocation({ ...location, locatie: e.target.value });
              setError("");
            }}
          />
        </div>
        {locationType !== "comenzi" && locationType !== "sediu" && (
          <div className="locatieInputWrapper">
            <p>Client</p>
            <input
              className="input_locatie"
              value={location ? location.client : ""}
              onChange={(e) =>
                setLocation({ ...location, client: e.target.value })
              }
            />
          </div>
        )}

        {locationType === "psi" && (
          <div className="locatieInputWrapper">
            <p>Stingatoare</p>
            <input
              className="input_locatie_instalatie_psi"
              value={location ? location.instSt : ""}
              onChange={(e) => {
                setLocation({ ...location, instSt: e.target.value });
              }}
            />
            {locationType === "psi" && (
              <div
                className={
                  error === "stingatoare"
                    ? "locatieError scadenta_locatie_psi"
                    : "lucrareLocatie scadenta_locatie_psi"
                }
              >
                <CreatableSelect
                  placeholder={"Scadenta"}
                  styles={customStyles}
                  formatCreateLabel={() => null || undefined}
                  noOptionsMessage={() => null}
                  isClearable
                  isLoading={false}
                  isDisabled={false}
                  onBlurResetsInput={false}
                  onChange={(value) => {
                    setLocation({ ...location, terSt: value });
                    setError("");
                  }}
                  options={months}
                  value={location && location.terSt}
                />
              </div>
            )}
          </div>
        )}
        {locationType !== "comenzi" && (
          <div className="locatieInputWrapper">
            <p>{locationType === "psi" ? "Hidranti" : "Instalatie"}</p>
            <input
              className={
                locationType === "psi"
                  ? "input_locatie_instalatie_psi"
                  : "input_locatie"
              }
              value={
                locationType === "psi"
                  ? location && location.instHid
                  : location && location.descriere
              }
              onChange={(e) => {
                setLocation({
                  ...location,
                  descriere: e.target.value,
                  instHid: e.target.value,
                });
              }}
            />
            {locationType === "psi" && (
              <div
                className={
                  error === "hidranti"
                    ? "locatieError scadenta_locatie_psi"
                    : "lucrareLocatie scadenta_locatie_psi"
                }
              >
                <CreatableSelect
                  placeholder={"Scadenta"}
                  styles={customStyles}
                  formatCreateLabel={() => null || undefined}
                  noOptionsMessage={() => null}
                  isClearable
                  isLoading={false}
                  isDisabled={false}
                  onBlurResetsInput={false}
                  onChange={(value) => {
                    setLocation({ ...location, terHid: value });
                    setError("");
                  }}
                  options={months}
                  value={location && location.terHid}
                />
              </div>
            )}
          </div>
        )}
        {location && isLocatieCar(location.locatie) && (
          <>
            <div className="locatieInputWrapper">
              <p>ITP</p>
              <DatePicker
                locale="ro"
                className="input_locatie"
                dateFormat="dd/MM/yyyy"
                selected={location?.itp ? new Date(location.itp) : new Date()}
                value={
                  location?.itp ? `${showDateFormatFullYear(location.itp)}` : ``
                }
                onChange={(date) => {
                  setLocation({ ...location, itp: date });
                }}
              />
            </div>
            <div className="locatieInputWrapper">
              <p>Parcare</p>
              <DatePicker
                locale="ro"
                className="input_locatie"
                dateFormat="dd/MM/yyyy"
                selected={location?.parcare ? new Date(location.parcare) : new Date()}
                value={
                  location?.parcare ? `${showDateFormatFullYear(location.parcare)}` : ``
                }
                onChange={(date) => {
                  setLocation({ ...location, parcare: date });
                }}
              />
            </div>
            <div className="locatieInputWrapper">
              <p>RCA</p>
              <DatePicker
                locale="ro"
                className="input_locatie"
                dateFormat="dd/MM/yyyy"
                selected={location?.rca ? new Date(location.rca) : new Date()}
                value={
                  location?.rca ? `${showDateFormatFullYear(location.rca)}` : ``
                }
                onChange={(date) => {
                  setLocation({ ...location, rca: date });
                }}
              />
            </div>
            <div className="locatieInputWrapper">
              <p>Rovigneta</p>
              <DatePicker
                locale="ro"
                className="input_locatie"
                dateFormat="dd/MM/yyyy"
                selected={
                  location?.rovigneta
                    ? new Date(location.rovigneta)
                    : new Date()
                }
                value={
                  location?.rovigneta
                    ? `${showDateFormatFullYear(location.rovigneta)}`
                    : ``
                }
                onChange={(date) => {
                  setLocation({ ...location, rovigneta: date });
                }}
              />
            </div>
          </>
        )}
        {(locationType === "ascensoare" ||
          locationType === "revizii" ||
          locationType === "iscir") && (
          <div className="locatieInputWrapper">
            <p>Scadenta</p>
            <div
              className={
                error === "scadentaIscir"
                  ? "locatieScadentaError"
                  : "locatieInputScadentaAscensoare"
              }
            >
              <DatePicker
                locale="ro"
                className="input_locatie"
                dateFormat="dd/MM/yyyy"
                selected={location?.terIscir ? new Date(location.terIscir) : new Date()}
                value={
                  location?.terIscir
                    ? `${showDateFormatFullYear(
                      location?.terIscir
                    )}`
                    : "Data"
                }
                minDate={new Date()}
                onChange={(date) => {
                  setFaraScadentaIscir(false);
                  setLocation({
                    ...location,
                    terIscir: date
                  });
                }}
              />
            </div>
            <div className="ml-4">Fara Scadenta</div>
            <input
              className="ml-1"
              type="checkbox"
              style={{
                width: "22px",
                height: "22px",
              }}
              checked={faraScadentaIscir}
              onChange={() => {setFaraScadentaIscir(!faraScadentaIscir); setLocation({...location, terIscir: null})}}
            />
          </div>
        )}
        {locationType !== "sediu" && (
          <div className="locatieInputWrapper">
            <p>Adresa</p>
            <input
              className={
                locationType === "psi"
                  ? "input_locatie_instalatie_psi"
                  : "input_locatie"
              }
              value={location ? location.adresa : ""}
              onChange={(e) =>
                setLocation({ ...location, adresa: e.target.value })
              }
            />
            {locationType === "psi" && (
              <div
                className={
                  error === "zona"
                    ? "locatieError scadenta_locatie_psi"
                    : "lucrareLocatie scadenta_locatie_psi"
                }
              >
                <CreatableSelect
                  className={isRed ? "xxx" : "yyy"}
                  styles={customStylesZona}
                  onBlur={(e) => {
                    if (!(location && location.zona)) {
                      setLocation({
                        ...location,
                        zona: { value: e.target.value, label: e.target.value },
                      });
                      setZone([
                        ...zone,
                        { value: e.target.value, label: e.target.value },
                      ]);
                      setError("");
                    }
                  }}
                  placeholder={"Zona"}
                  formatCreateLabel={() => null || undefined}
                  noOptionsMessage={() => null}
                  isClearable
                  isLoading={false}
                  isDisabled={false}
                  onBlurResetsInput={false}
                  onInputChange={(value) => {
                    setError("");
                    let list = zone.filter(
                      (location) =>
                        location.label &&
                        location.label
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    );
                    if (value) {
                      if (list.length === 0) {
                        setLocation({ ...location, zona: null });
                        setIsRed(true);
                      } else setIsRed(false);
                    }
                  }}
                  onChange={(value) => {
                    setLocation({ ...location, zona: value });
                    setError("");
                    if (isSelectedZoneNew(value && value.value)) {
                      setIsRed(true);
                    } else setIsRed(false);
                  }}
                  options={zone}
                  onCreateOption={(value) => {
                    setLocation({ ...location, zona: { value, label: value } });
                    setZone([...zone, { value, label: value }]);
                    setError("");
                  }}
                  value={location && location.zona}
                />
              </div>
            )}
          </div>
        )}
        {locationType !== "sediu" && (
          <div className="locatieInputWrapper">
            <p>Persoana</p>
            <input
              className="input_locatie"
              value={location ? location.persoana : ""}
              onChange={(e) =>
                setLocation({ ...location, persoana: e.target.value })
              }
            />
          </div>
        )}
        {locationType !== "sediu" && (
          <div className="locatieInputWrapper">
            <p>Telefon</p>
            <input
              className="input_locatie"
              value={location ? location.tel : ""}
              onChange={(e) =>
                setLocation({ ...location, tel: e.target.value })
              }
            />
          </div>
        )}
        {locationType !== "sediu" && (
          <div className="locatieInputWrapper">
            <p>E-Mail</p>
            <input
              className="input_locatie"
              value={location ? location.email : ""}
              onChange={(e) =>
                setLocation({ ...location, email: e.target.value })
              }
            />
          </div>
        )}
        {locationType !== "comenzi" && locationType !== "sediu" && (
          <div className="locatieInputWrapper">
            <p>Contract</p>
            <input
              className="input_locatie"
              value={location ? location.contract : ""}
              onChange={(e) =>
                setLocation({ ...location, contract: e.target.value })
              }
            />
          </div>
        )}
        {locationType !== "comenzi" && locationType !== "sediu" && (
          <div className="locatieInputWrapper">
            <p>CUI</p>
            <input
              className="input_locatie"
              type="number"
              value={location ? location.cui : ""}
              onChange={(e) =>
                setLocation({ ...location, cui: e.target.value })
              }
            />
          </div>
        )}
        {locationType === "ascensoare" && (
          <div className="locatieInputWrapper">
            <p>Firma</p>
            <input
              className="input_locatie"
              value={location ? location.firma : ""}
              onChange={(e) =>
                setLocation({ ...location, firma: e.target.value })
              }
            />
          </div>
        )}
      </div>
      <Row className="mt-3">
        <Col className="text-center">
          {location && location.temporar ? (
            <ButtonComponent
              text={"Preluare"}
              onClick={preluareLocationType}
              variant={error ? "danger" : "warning"}
              disabled={blocking}
            />
          ) : (
            <ButtonComponent
              text={
                locationId ? (addFromClient ? "Adauga" : "Modifica") : "Adauga"
              }
              disabled={blocking}
              onClick={addLocationType}
              variant={
                error
                  ? "danger"
                  : locationId
                  ? addFromClient
                    ? "primary"
                    : "warning"
                  : "primary"
              }
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Location;
