import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./components/Login";
import UserContextProvider from "./context/UserContext";
import RefreshContextProvider from "./context/RefreshContext";
import Locations from "./locations/Locations";
import { Container } from "react-bootstrap";
import { getUserName } from "./backend/RESTUtils";
import Interventii from "./components/Interventii";
import Ascensoare from "./components/Ascensoare";
import Programate from "./components/Programate";
import ProgramateImportante from "./components/ProgramateImportante";
import Revizii from "./components/Revizii";
import Psi from "./components/Psi";
import Interfoane from "./components/Interfoane";
import FisaZilnica from "./components/FisaZilnica";
import Altele from "./components/Altele";
import SediuMasini from "./components/SediuMasini";
import Diverse from "./components/Diverse";
import Finalizate from "./components/Finalizate";
import Setari from "./setari/Setari";
import Pontaje from "./components/Pontaje";
import Administrativ from "./components/Administrativ";
import Notificari from "./components/Notificari";
import Comenzi from "./components/Comenzi";
import Iscir from "./components/Iscir";
import Oferte from "./components/Oferte";
import LeftDashboard from "./components/LeftDashboard";
import Remindere from "./components/Remindere"
import { Row, Col } from "react-bootstrap";
import NewCompanyDomain from "./components/NewCompanyDomain";
import FolderLocatie from "./components/FolderLocatie";
import DocumentSlider from "./components/DocumentSlider";
import About from "./components/About";
import NewMediaViewer from "./components/NewMediaViewer";
import NewMediaSlider from "./components/NewMediaSlider";
import DocumenteLocatieViewer from "./components/DocumenteLocatieViewer";

const PrivateRoute = ({ component: Component, customProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        getUserName() ? <Component {...props} {...customProps} /> : <Redirect to="/" />}
      
    />
  );
};

const App = () => {
  return (
    <Container className="black_screen" fluid>
      <BrowserRouter>
        <Switch>
          <UserContextProvider>
            <RefreshContextProvider>
              <Row >
            <LeftDashboard domeniu={"interventii"} />
            <Col md={10} className="right_dashboard">
              <Route exact path="/" component={Login} />
              <PrivateRoute exact path="/interventii" component={Interventii} />
              <PrivateRoute exact path="/locatii" component={Locations} />
              <PrivateRoute exact path="/psi" component={Psi} />
              <PrivateRoute exact path="/notificari" component={Notificari} />

              <PrivateRoute
                exact
                path="/administrativ"
                component={Administrativ}
              />
              <PrivateRoute exact path="/programate" component={Programate} />
              <PrivateRoute exact path="/programateImportante" component={ProgramateImportante} />
              <PrivateRoute exact path="/remindere" component={Remindere} />
              <PrivateRoute exact path="/ascensoare" component={Ascensoare} />
              <PrivateRoute exact path="/smartSignage" component={NewCompanyDomain} customProps={{locationType: "Smart_Signage"}}/>
              <PrivateRoute exact path="/software" component={NewCompanyDomain} customProps={{locationType: "Software"}}/>
              <PrivateRoute exact path="/controllers" component={NewCompanyDomain} customProps={{locationType: "Controllers"}}/>
              <PrivateRoute exact path="/personalizariTextile" component={NewCompanyDomain} customProps={{locationType: "Personalizari_textile"}}/>
              <PrivateRoute exact path="/personalizariObiecte" component={NewCompanyDomain} customProps={{locationType: "Personalizari_obiecte"}}/>
              <PrivateRoute exact path="/tiparituri" component={NewCompanyDomain} customProps={{locationType: "Tiparituri"}}/>
              <PrivateRoute exact path="/revizii" component={Revizii} />
              <PrivateRoute exact path="/interfoane" component={Interfoane} />
              <PrivateRoute exact path="/pontaje" component={Pontaje} />
              <PrivateRoute exact path="/fisaZilnica" component={FisaZilnica} />
              <PrivateRoute exact path="/altele" component={Altele} />
              <PrivateRoute exact path="/iscir" component={Iscir} />
              <PrivateRoute exact path="/sediuMasini" component={SediuMasini} />
              <PrivateRoute exact path="/diverse" component={Diverse} />
              <PrivateRoute exact path="/comenzi" component={Comenzi} />
              <PrivateRoute exact path="/oferte" component={Oferte} />

              <PrivateRoute
                exact
                path="/istoricLocatie"
                component={Finalizate}
              />
              <PrivateRoute exact path="/folderLocatie" component={FolderLocatie} />
              <PrivateRoute exact path="/documentSlider" component={DocumentSlider} />
              <PrivateRoute exact path="/setari" component={Setari} />
              <PrivateRoute exact path="/about" component={About}/>
              <PrivateRoute exact path="/newMediaViewer" component={NewMediaViewer} />
              <PrivateRoute exact path="/newMediaSlider" component={NewMediaSlider} />
              <PrivateRoute exact path="/documenteLocatieViewer" component={DocumenteLocatieViewer} />
              </Col>
              </Row>
            </RefreshContextProvider>
          </UserContextProvider>
        </Switch>
      </BrowserRouter>
    </Container>
  );
};

export default App;
