import React from "react";

const ListUseri = ({ onClickUser, classUser, className, listUsers = [] }) => {
  return (
    <div className={className}>
      {listUsers.map((user, index) => (
        <div
          key={index}
          onClick={() => onClickUser(user)}
          className={classUser(user)}
        >
          {user}
        </div>
      ))}
    </div>
  );
};

export default ListUseri;
