import React, { useState, useEffect, useContext } from "react";

import { Row, Col, Image } from "react-bootstrap";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import { deleteLocatieDocument, getDocumentFileNames, getFoldersForLocation } from "../backend/FileUploadService";
import { useLocation } from "react-router-dom";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";
import DocumentLocatieItem from "./DocumentLocatieItem";
import ConfirmActionComponent from "./basic/ConfirmActionComponent";
import ModalComponent from "./basic/ModalComponent";

const DocumenteLocatieViewer = () => {
  const [documentsNames, setDocumentsNames] = useState([]);
  const [triggerLucrari, setTriggerLucrari] = useState(false);
  const [activeTab, setActiveTab] = useState("locatie");
  const location = useLocation();
  const { internet, serverError, triggerLocatieDocuments, refreshLocatieDocuments } = useContext(RefreshContext);
  const [responseReceived, setResponseReceived] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState();
  const [error, setError] = useState();

  //prop trimise prin history.push
  const { locationType, locatieName, locatieId, currentLucrare } = location.state;
  const setTab = (value) => {
    setActiveTab(value);
  };

  const refreshLucrari = () => {
    setTriggerLucrari(!triggerLucrari);
  };

  const getDocumentNames = async () => {
    try {
      const result = await getDocumentFileNames(locationType, `${locatieName}_${locatieId}`);
      setDocumentsNames(result);
      setResponseReceived(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getDocumentNames();
  }, [triggerLocatieDocuments]);

  const deleteDocument = async () => {
    try {
      await deleteLocatieDocument(locationType, `${locatieName}_${locatieId}`, documentToDelete);
      refreshLocatieDocuments();
      setDocumentToDelete(null);
    } catch (err) {
      setDocumentToDelete(null);
      setError("Eroare la stergerea documentului");
    }
  }


  return (
    <Row>
      {documentToDelete != null && <ConfirmActionComponent
        buttonIsPressed={true}
        showButtons={false}
        yesPressAction={deleteDocument}
        noPressAction={() => setDocumentToDelete(null)}
        text={`Esti sigur ca vrei sa stergi documentul?`}
      />}
      <>{error && (
        <ModalComponent
            size={error ? "sm" : "md"}
            show={error !== null}
            close={() => setError()}
            body={<h3 style={{color: "red"}}>{error}</h3>}
          />      
      )}</>
      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper">
                <div className="lucrari_cu_fisiere_header">
                  <h2>Documente</h2>
                </div>
              </div>
            </Row>
            <Row className="middleScreen">
              <Col>
                {documentsNames.length === 0 && responseReceived === true && (
                  <div className="lucrari_not_found">
                    Nu exista documente pentru aceasta locatie.
                  </div>
                )}

                {documentsNames.map(
                  (documentData, index) => (
                    <DocumentLocatieItem
                      key={index}
                      documentData={documentData}
                      locationType={locationType}
                      locationFolderName={`${locatieName}_${locatieId}`}
                      setDocumentToDelete={setDocumentToDelete}
                    />
                  )
                )}
              </Col>
            </Row>
          </>
        ) : (
          <div className="no_internet_wrapper">
            <Image src={logo_image} />
            <div className="no_internet_text">
              {serverError
                ? `Nu este conexiune la server`
                : `Nu este conexiune la internet`}
            </div>
          </div>
        )}
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLucrare
          locationType={
            location?.state?.locationType
          }
          currentLucrare={currentLucrare}
          activeTab={activeTab}
          setTab={setTab}
          refreshLucrari={refreshLucrari}
          locatieName={location && location.state && location.state.locatieName}
          locatieId={location && location.state && location.state.locatieId}
        />
      </Col>
    </Row>
  );
};
export default DocumenteLocatieViewer;
