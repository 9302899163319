import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import ObservatieLocatie from "./observatie/ObservatieLocatie";
import ModalComponent from "../components/basic/ModalComponent";
import { RefreshContext } from "../context/RefreshContext";
import Location from "./Location";

const LocationItem = ({
  location,
  setCurrentLocation,
  currentLocation,
  setTab,
  getBackgroundColorLocatie,
  refreshLocations,
  locations,
}) => {
  const [modalObservatie, setModalObservatie] = useState(false);
  const [modalLocatie, setModalLocatie] = useState(false);
  const { refreshObservatiiLocatie } = useContext(RefreshContext);

  const openLocatieModal = () => {
    if (location.temporar) return;
    setModalLocatie(true);
  };
  const closeLocatieModal = () => {
    setModalLocatie(false);
  };
  const openModalObservatie = () => {
    setModalObservatie(true);
  };

  const closeModalObservatie = () => {
    setModalObservatie(false);
  };

  const rightClickLocatie = (e) => {
    e.preventDefault();
    openLocatieModal();
  };

  const rightClickDescriere = (e) => {
    e.preventDefault();
    setCurrentLocation(location);
    refreshObservatiiLocatie();
    setTab("observatii");
    openModalObservatie();
  };

  const getTextColoLocatie = () => {
    if (location && location.temporar) {
      return "lucrareItemLocatieTextColorRed";
    } else {
      return "lucrareItemLocatieTextColorNormal";
    }
  };
  const getWrapperDescriere = () => {
    let classStyle = "";
    if (location.observatii) {
      classStyle = "locatie_item_descriere_with_observarii";

      if (location.activ) classStyle = classStyle + " backgroudLocatieActive";
      else classStyle = classStyle + " backgroudLocatieInactiv";
    } else {
      classStyle = "locatie_item_descriere";
      if (location.activ) classStyle = classStyle + " backgroudLocatieActive";
      else classStyle = classStyle + " backgroudLocatieInactiv";
    }

    return classStyle;
  };

  const backgroundColorActive = () => {
    if (location.activ) return "backgroudLocatieActive";
    else return "backgroudLocatieInactiv";
  };

  return (
    <div className="locationItem_wrapper">
      <Row
        className={
          currentLocation?.id === location.id ? "selectedLucrare" : "mb-1"
        }
      >
        <Col
          className={`${getBackgroundColorLocatie()} ${getTextColoLocatie()} locatie_item_locatie`}
          onClick={() => {
            setCurrentLocation(location);
            setTab("locatie");
          }}
          onContextMenu={(e) => rightClickLocatie(e)}
          md={2}
        >
          {location.locatie}
        </Col>

        <Col
          className={`${backgroundColorActive()} locatie_item_client`}
          md={3}
          onClick={() => {
            setCurrentLocation(location);
            setTab("detalii");
          }}
        >
          {location.client}
        </Col>

        <Col
          onContextMenu={(e) => rightClickDescriere(e)}
          onClick={() => {
            setCurrentLocation(location);
            refreshObservatiiLocatie();
            setTab("observatii");
          }}
          className={`${getWrapperDescriere()}`}
          md={7}
        >
          {location.observatii ? (
            <div className="lucrare_item_descriere_obs_wrapper">
              <div className="locatie_item_descriere_obs">
                {location.instSt || location.instHid ? (
                  <div className="text-center w-100">
                    {location.instSt && <div>{location.instSt}</div>}
                    {location.instHid && <div>{location.instHid}</div>}
                  </div>
                ) : (
                  <>{location.descriere}</>
                )}
              </div>

              <div className="baraRosieObservatii"></div>
            </div>
          ) : (
            <>
              {location.instSt || location.instHid ? (
                <div className="text-center w-100">
                  {location.instSt && <div>{location.instSt}</div>}
                  {location.instHid && <div>{location.instHid}</div>}
                </div>
              ) : (
                <>{location.descriere}</>
              )}
            </>
          )}
        </Col>
      </Row>
      <ModalComponent
        show={modalLocatie}
        size="lg"
        close={() => setModalLocatie(false)}
        headingText={
          location && location.type === "comenzi"
            ? "Adauga Furnizor"
            : "Adauga Locatie"
        }
        body={
          <Location
            closeModal={closeLocatieModal}
            refreshLocations={refreshLocations}
            locationType={location && location.type}
            locationId={location && location.id}
            addFromClient={true}
            locations={locations}
          />
        }
      />
      <ModalComponent
        size={"md"}
        show={modalObservatie}
        close={closeModalObservatie}
        headingText={"Observatie"}
        body={
          <ObservatieLocatie
            locatieId={location.id}
            closeModalObservatie={closeModalObservatie}
            setTab={setTab}
          />
        }
      />
    </div>
  );
};
export default LocationItem;
