import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Image } from "react-bootstrap";
import {
   getRemindersService,
} from "../backend/LucrareService";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";
import { refreshTimer } from "../utils/Values";
import { useHistory } from "react-router-dom";
import ReminderItem from "../lucrare/ReminderItem";
import { RxCalendar } from "react-icons/rx";

const Remindere = () => {
  const [showModal, setShowModal] = useState(false);
  const [remindere, setRemindere] = useState([]);
  const [triggerLucrari, setTriggerLucrari] = useState(false);
  const [currentLucrare, setCurrentLucrare] = useState();
  const [currentReminder, setCurrentReminder] = useState();
  const [activeTab, setActiveTab] = useState("locatie");
  const [refresh, setRefresh] = useState(false);
  const { internet, serverError } = useContext(RefreshContext);

  const setTab = (value) => {
    setActiveTab(value);
  };

  const refreshLucrari = () => {
    setTriggerLucrari(!triggerLucrari);
  };

  const history = useHistory();

  const getReminders = async () => {
    try {
      const result = await getRemindersService();
      setRemindere(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const id = startRefreshTimer();
    return () => {
      clearInterval(id);
    };
  }, [refresh]);

  const startRefreshTimer = () => {
    return setInterval(() => {
      setRefresh(!refresh);
    }, refreshTimer);
  };

  useEffect(() => {
    getReminders();
  }, [triggerLucrari, refresh]);

  return (
    <Row>
      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper psi_header_wrapper">
                <div
                  className="administrativ_header"
                  onClick={() => setShowModal(true)}
                >
                  <h2>Remindere</h2>
                </div>
                <div className="psi_header_months">

                  <div
                    onClick={() => { history.push("/programate"); }}
                    className={"administrativ_header_button"} >
                     <RxCalendar size={32}/>
                  </div>
                </div>
              </div>
            </Row>
            <Row className="middleScreen">
              <Col>
                {remindere.map((reminder, index) => (
                  <ReminderItem
                    key={index}
                    refreshLucrari={refreshLucrari}
                    reminder={reminder}
                    currentLucrare={currentLucrare}
                    setCurrentLucrare={setCurrentLucrare}
                    currentReminder={currentReminder}
                    setCurrentReminder={setCurrentReminder}
                    setTab={setTab}
                  />
                ))}
              </Col>
            </Row>
           
          </>
        ) : (
          <div className="no_internet_wrapper">
            <Image src={logo_image} />
            <div className="no_internet_text">
              {serverError
                ? `Nu este conexiune la server`
                : `Nu este conexiune la internet`}
            </div>
          </div>
        )}
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        <TabsLucrare
          locationType="administrativ"
          currentLucrare={currentLucrare}
          setCurrentLucrare={setCurrentLucrare}
          activeTab={activeTab}
          setTab={setTab}
          refreshLucrari={refreshLucrari}
        />
      </Col>
    </Row>
  );
};

export default Remindere;
