import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ButtonComponent from "../components/basic/ButtonComponent";
import { updateUserFinanceService } from "../backend/SetariService";

const UserDispecer = ({ user }) => {
  const [setare, setSetare] = useState();

  useEffect(() => {
    setSetare(user);
  }, [user]);

  const editUserFinance = async () => {
    try {
      await updateUserFinanceService(setare.id, {
        leiOra: setare.leiOra,
        avans: setare.avans,
        pontaj: setare.pontaj
      });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Row className="mb-1">
      <Col>{`${user.firstName} ${user.lastName}`}</Col>
      <Col>{user.userName}</Col>

      <Col>
        <ButtonComponent
          text={"Edit"}
          onClick={editUserFinance}
          variant="warning"
        />
      </Col>
    </Row>
  );
};

export default UserDispecer;
