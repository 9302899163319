import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { showDateFormat, showTimeFormat } from "../../utils/Utils";
import "../../style/ObservatieItem.css";
import ModalComponent from "../../components/basic/ModalComponent";
import ObservatieLucrare from "./ObservatieLucrare";
import { UserContext } from "../../context/UserContext";
import { database } from "../../firebase";
import { markAsReadService } from "../../backend/ObservatieService";
import { RefreshContext } from "../../context/RefreshContext";
import { ref, get } from 'firebase/database';

const ObservatieLucrareItem = ({ observatie, refreshLucrari, lucrareId }) => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const { isAdmin, isDispecer, user } = useContext(UserContext);
  const { refreshObservatiiLucrare } = useContext(RefreshContext);

  const rightClickObservatieText = async e => {
    e.preventDefault();

    if (!observatie.userCitit) {
      await markAsReadService(observatie.id);
      refreshObservatiiLucrare();
      refreshLucrari();
    } else {
      setModal(true);
      if (
        observatie.author !== user.userRoleDTO.userName &&
        !isAdmin() &&
        !isDispecer()
      )
        setError("Nu poti modifica observatia!");
    }
  };

  const closeModal = () => {
    setModal(false);
    setError("");
  };

  const addNrTelefon = () => {
    if (isAdmin() || isDispecer()) {
      const itemsRef = ref(database, "logics");
      get(itemsRef).then(snapshot => {
        let items = snapshot.val();
        if (items === null) {
          if (observatie.partNr) {
            itemsRef.push({
              userName: user.userRoleDTO.userName,
              nr: observatie.partNr
            });
          }
        } else {
          for (let item in items) {
            if (items[item].userName === user.userRoleDTO.userName) {
              return;
            } else {
              if (observatie.partNr) {
                itemsRef.push({
                  userName: user.userRoleDTO.userName,
                  nr: observatie.partNr
                });
              }
            }
          }
        }
      });
    }
  };

  const observatieCitiStyle = () => {
    if (observatie.userCitit) return "rightComponentLabel";
    else return "rightComponentLabelRed";
  };
  return (
    <>
      <Row className={"locatie_lucrare_wrapper_big"}>
        <Col>
          <div onContextMenu={e => rightClickObservatieText(e)}>
            <div className="rightComponentWrapper">
              <p className={`${observatieCitiStyle()} d-inline-block mr-2`}>
                {`${showDateFormat(new Date(observatie.date))} ${showTimeFormat(
                  new Date(observatie.date)
                )} ${observatie.author.toUpperCase()}`}
              </p>
              <div className={"rightComponentValue d-inline-block"}>
                {observatie.partNr ? (
                  <div className="lucrare_with_nr_tel">
                    <div className="d-inline">{observatie.part1}</div>
                    <div onClick={addNrTelefon} className="d-inline nr_telefon">
                      {observatie.partNr}
                    </div>
                    <div className="d-inline">{observatie.part2}</div>
                  </div>
                ) : (
                  <div>{observatie.text}</div>
                )}
              </div>
            </div>
          </div>
          <ModalComponent
            size={error ? "sm" : "md"}
            show={modal}
            close={closeModal}
            headingText={"Modificare Observatie"}
            body={
              error ? (
                <p>{error}</p>
              ) : (
                <ObservatieLucrare
                  lucrareId={lucrareId}
                  closeModalObservatie={closeModal}
                  initialObservatie={observatie}
                  readOnly={true}
                />
              )
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default ObservatieLucrareItem;
