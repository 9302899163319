import React, { useState, useEffect, useContext, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import TextAreaComponent from "../../components/basic/TextAreaComponent";
import ButtonComponent from "../../components/basic/ButtonComponent";
import {
  addObservatieLucrareService,
  finishRevizie,
  updateObservatieLucrareService,
  deleteObservatieLucrareService
} from "../../backend/ObservatieService";
import { RefreshContext } from "../../context/RefreshContext";
import { UserContext } from "../../context/UserContext";
import "../../style/ObservatieItem.css";

const ObservatieLucrare = ({
  lucrareId,
  closeModalObservatie,
  setTab,
  initialObservatie,
}) => {
  const { refreshObservatiiLucrare, blocking, setBlocking } = useContext(RefreshContext);

  const [observatie, setObservatie] = useState();
  const [error, setError] = useState("");
  const [infomsg, setInfomsg] = useState("");
  const [tvt, setTvt] = useState("");
  const isMounted = useRef(true);
  const { isAdmin, isDispecer, user } = useContext(UserContext);

  const canDeleteObservatie = () => {
    return initialObservatie && (isAdmin() || isDispecer() || initialObservatie.author === user.userRoleDTO.userName);
  }
  // set isMounted to false when we unmount the component
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, []);

  useEffect(() => {
    if (initialObservatie) setObservatie(initialObservatie);
  }, []);

  useEffect(() => {
    setInfomsg(tvt);
  }, [tvt]);

  const addOrUpdateObservatie = async () => {
    setBlocking(true)
    try {
      if (initialObservatie) {
        await updateObservatieLucrareService(observatie);
        closeModalObservatie();
      } else {
        await addObservatieLucrareService(lucrareId, observatie);
        if (observatie.text === "rtc") {
          await finishRevizie(lucrareId).then((a) => { setTvt(a); });
        } else {
          closeModalObservatie();
        }
        setTab("observatii");
      }

      refreshObservatiiLucrare();

    } catch (err) {
      console.error(err);
      setError(err.response.data.message);
    }
    finally {
      setBlocking(false)
    }
  };

  const deleteObservatie = async () => {
    setBlocking(true)
    try {
      await deleteObservatieLucrareService(observatie.id, lucrareId);
      closeModalObservatie();
      refreshObservatiiLucrare();

    } catch (err) {
      console.error(err);
      setError(err.response.data.message);
    }
    finally {
      setBlocking(false)
    }
  };

  return (
    <div>
      <>{error && (<h3 style={{ color: `#c60800` }}>{error}</h3>)}</>
      <>{infomsg && (<h3>{infomsg}</h3>)}</>
      <>{!error && !infomsg && (<div><Row className="mb-3">
        <Col>
          <TextAreaComponent
            placeholder="Observatie detalii"
            value={observatie ? observatie.text : ""}
            onChange={e =>
              setObservatie({ ...observatie, text: e.target.value })
            }
            rows={4}
            style={{width: "100%"}}
            autoFocus
          />
        </Col>
      </Row>

        <Row>
          {canDeleteObservatie() && (<div
            onClick={deleteObservatie}
            className="deleteObservatieButton"
            style={{ marginLeft: 15 }}>
            S
          </div>)}
          <Col className="text-center">
            <ButtonComponent className="deleteObservatieButton"
              text={
                initialObservatie
                  ? "Modificare"
                  : "Adaugare"
              }
              onClick={addOrUpdateObservatie}
              disabled={blocking}
            />
          </Col>
        </Row></div>)}
      </>
    </div>
  );
};



export default ObservatieLucrare;
