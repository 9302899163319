import React, { useState, useContext } from "react";
import "../style/PontajItem.css";
import { getRomanianDay, showDateFormat } from "../utils/Utils";
import ModalComponent from "../components/basic/ModalComponent";
import Pontaj from "./Pontaj";
import { UserContext } from "../context/UserContext";

const PontajItem = ({ pontaj, refreshPontaje }) => {
  const [showModal, setSHowModal] = useState(false);
  const { isAdmin } = useContext(UserContext);

  const closeModal = () => {
    setSHowModal(false);
  };
  const getBackgroundColor = () => {
    switch (getRomanianDay(new Date(pontaj.date))) {
      case "Sambata":
        return "pontajBackgroundSambata";
      case "Duminica":
        return "pontajBackgroundDuminica";
      default:
        return "pontajBackground";
    }
  };

  const getTextColor = () => {
    if (
      getRomanianDay(new Date(pontaj.date)) === "Sambata" ||
      getRomanianDay(new Date(pontaj.date)) === "Duminica"
    ) {
      return "text_color_white";
    } else {
      return "text_color_black";
    }
  };

  const getBackgroundColorItemDown = () => {
    if (pontaj.validat) {
      return "backgroundColorGreen";
    } else if (
      pontaj.ore !== null ||
      pontaj.suplimentare !== null ||
      pontaj.interventie1 ||
      pontaj.interventie2 ||
      pontaj.telefon ||
      pontaj.telefon2
    ) {
      return "backgroundColorWhite";
    } else return "";
  };

  const getDifferenceDay = () => {
    let date1 = new Date();
    let date2 = new Date(pontaj.date);

    // To calculate the time difference of two dates
    let Difference_In_Time = date1.getTime() - date2.getTime();

    // To calculate the no. of days between two dates
    let days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

    return days;
  };

  const rightClickPontajItem = e => {
    e.preventDefault();

    setSHowModal(true);
  };

  return (
    <div
      onContextMenu={e => rightClickPontajItem(e)}
      className={`pontaj_item_wrapper ${getBackgroundColor()}`}
    >
      <div className={`pontaj_item_up`}>
        <div className="pontaj_item_up_user">
          <div className={`${getTextColor()} ml-2`}>
            {getRomanianDay(new Date(pontaj.date))}
          </div>
          <div className={`${getTextColor()} ml-2`}>
            {showDateFormat(new Date(pontaj.date))}
          </div>
          <div className={`${getTextColor()} ml-2`}>
            {pontaj.user.toUpperCase()}
          </div>
        </div>
        <div className="pontaj_item_up_validator">
          {pontaj.validator !== null && (
            <div className={`${getTextColor()}`}>
              {pontaj.validator.toUpperCase()}
            </div>
          )}
        </div>
      </div>
      <div className={`pontaj_item_down ${getBackgroundColorItemDown()}`}>
        {pontaj.optiune !== "NONE" ? (
          <div>{pontaj.optiune}</div>
        ) : (
          <>
            <div className="ml-2">({pontaj.oreLucrari})</div>
            <div className="ml-2">
              {pontaj.ore === null || pontaj.ore === 0
                ? ""
                : `OL: ${pontaj.ore}`}{" "}
              {(getRomanianDay(new Date(pontaj.date)) === "Sambata" ||
                getRomanianDay(new Date(pontaj.date)) === "Duminica") &&
                pontaj.ore !== null &&
                pontaj.ore !== 0 &&
                ` [${pontaj.ore * 1.5}]`}
            </div>
            {pontaj.interventie1 && <div className="ml-2">Inter</div>}
            {pontaj.interventie2 && <div className="ml-2">Inter</div>}
            {pontaj.telefon && <div className="ml-2">Tel</div>}
            {pontaj.telefon2 && <div className="ml-2">Tel</div>}
            <div className="ml-2">
              {pontaj.suplimentare === null || pontaj.suplimentare === 0
                ? ""
                : `OS: ${pontaj.suplimentare}`}{" "}
              {(getRomanianDay(new Date(pontaj.date)) === "Sambata" ||
                getRomanianDay(new Date(pontaj.date)) === "Duminica") &&
                pontaj.suplimentare !== null &&
                pontaj.suplimentare !== 0 &&
                ` [${pontaj.suplimentare * 1.5}]`}
            </div>
          </>
        )}
      </div>
      <ModalComponent
        show={showModal}
        close={closeModal}
        headingText="Pontaj"
        body={
          <>
            {isAdmin() ? (
              <>
                {getDifferenceDay() >= 35 ? (
                  <div>Poti modifica/valida pana la 35 zile in urma</div>
                ) : (
                  <Pontaj
                    closeModal={closeModal}
                    pontaj={pontaj}
                    refreshPontaje={refreshPontaje}
                  />
                )}
              </>
            ) : (
              <>
                {getDifferenceDay() >= 14 ? (
                  <div>Poti modifica/valida pana la 14 zile in urma</div>
                ) : (
                  <Pontaj
                    closeModal={closeModal}
                    pontaj={pontaj}
                    refreshPontaje={refreshPontaje}
                  />
                )}
              </>
            )}
          </>
        }
      />
    </div>
  );
};

export default PontajItem;
