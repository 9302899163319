import { doGet, doPost } from "./RESTUtils";

export const getSetariService = async () => {
  return await doGet(`/Logics/setari/get`);
};

export const updateSetariService = async (id, setare) => {
  return await doPost(`/Logics/setari/update/${id}`, setare);
};

export const getUsersService = async () => {
  return await doGet(`/Logics/user/list`);
};

export const updateUserFinanceService = async (userId, finance) => {
  return await doPost(`/Logics/user/updateFinance/${userId}`, finance);
};