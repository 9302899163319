import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Image } from "react-bootstrap";
import TabsLucrare from "../tabs_lucrare/TabsLucrare";
import {
  getFisaZilnicaFirmaService,
  getFisaZilnicaUserService,
} from "../backend/LucrareService";
import LucrareItem from "../lucrare/LucrareItem";
import {
  showDateFormatWithHyphens,
  checkDatesEquals,
  showDateFormatFullYear,
  getRomanianDay,
} from "../utils/Utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TabsPontaje from "../tabs_pontaj/TabsPontaje";
import ListUseri from "./ListUseri";
import { RefreshContext } from "../context/RefreshContext";
import logo_image from "../imgs/logo.png";
import { listTehnicieni } from "../utils/Values";
import { refreshTimer } from "../utils/Values";
import { registerLocale } from "react-datepicker";
import ro from 'date-fns/locale/ro';

const FisaZilnica = () => {
  const [lucrari, setLucrari] = useState([]);
  const [triggerLucrari, setTriggerLucrari] = useState(false);
  const [currentLucrare, setCurrentLucrare] = useState();
  const [activeTab, setActiveTab] = useState("locatie");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [refresh, setRefresh] = useState(false);
  const [rightTabPontaj, setRightTabPontaj] = useState(false);
  const { internet, serverError, currentUser, setCurrentUser } =
    useContext(RefreshContext);

  const setTab = (value) => {
    setActiveTab(value);
  };

  const refreshLucrari = () => {
    setTriggerLucrari(!triggerLucrari);
  };

  const getFisaZilnicaFirma = async () => {
    try {
      const result = await getFisaZilnicaFirmaService(
        showDateFormatWithHyphens(new Date(currentDate))
      );
      setLucrari(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const id = startRefreshTimer();
    return () => {
      clearInterval(id);
    };
  }, [refresh]);

  const startRefreshTimer = () => {
    return setInterval(() => {
      setRefresh(!refresh);
    }, refreshTimer);
  };

  const getFisaZilnicaUser = async () => {
    try {
      const result = await getFisaZilnicaUserService(
        showDateFormatWithHyphens(new Date(currentDate)),
        currentUser
      );
      setLucrari(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (currentUser !== null) {
      getFisaZilnicaUser();
    } else {
      getFisaZilnicaFirma();
    }
  }, [triggerLucrari, refresh, currentDate, currentUser]);

  useEffect(() => {
    registerLocale('ro', ro);
  });

  return (
    <Row>
      <Col md={9}>
        {internet && !serverError ? (
          <>
            <Row className={"mb-3"}>
              <div className="headerWrapper">
                <div className="pontaje_header">
                  <div
                    className="pontaj_header_title"
                    onClick={() => {
                      setCurrentUser(null);
                      setActiveTab("validare");
                    }}
                  >
                    <h2>Fisa Zilnica</h2>
                  </div>

                  <ListUseri
                    listUsers={listTehnicieni}
                    className={"pontaj_header_useri_wrapper"}
                    onClickUser={(user) => {
                      setRightTabPontaj(true);
                      setActiveTab("pontajTehnician");
                      setCurrentUser(user.toLowerCase());
                    }}
                    classUser={(user) => {
                      return currentUser === user.toLowerCase()
                        ? "pontaj_header_user_item_selected"
                        : "pontaj_header_user_item";
                    }}
                  />

                  <div className="pontaj_header_date_wrapper">
                    <DatePicker
                      locale="ro"
                      className="date_picker_input"
                      dateFormat="MM/dd/yyyy"
                      selected={currentDate}
                      value={`${showDateFormatFullYear(
                        new Date(currentDate)
                      )} ${getRomanianDay(new Date(currentDate))}`}
                      maxDate={new Date()}
                      calendarStartDay={2}
                      onChange={(date) => {
                        setCurrentDate(date);
                        refreshLucrari();
                      }}
                    />
                    <div className="pontaj_header_date_sageti_wrapper">
                      <div
                        onClick={() => {
                          if (
                            checkDatesEquals(new Date(currentDate), new Date())
                          )
                            return;
                          setCurrentDate(
                            new Date(currentDate).getTime() +
                              1000 * 60 * 60 * 24
                          );
                        }}
                        className={
                          checkDatesEquals(new Date(currentDate), new Date())
                            ? "sageata_up_disabled"
                            : "sageata_up"
                        }
                      ></div>
                      <div
                        onClick={() => {
                          setCurrentDate(
                            new Date(currentDate).getTime() -
                              1000 * 60 * 60 * 24
                          );
                        }}
                        className="sageata_down"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>

            <Row className="middleScreen mt-2">
              <Col>
                {lucrari.map((lucrare) => (
                  <LucrareItem
                    key={lucrare.uid}
                    refreshLucrari={refreshLucrari}
                    lucrare={lucrare}
                    currentLucrare={currentLucrare}
                    setCurrentLucrare={setCurrentLucrare}
                    setTab={setTab}
                    fromFisaZilnica={true}
                    setRightTabPontaj={setRightTabPontaj}
                  />
                ))}
              </Col>
            </Row>
          </>
        ) : (
          <div className="no_internet_wrapper">
            <Image src={logo_image} />
            <div className="no_internet_text">
              {serverError
                ? `Nu este conexiune la server`
                : `Nu este conexiune la internet`}
            </div>
          </div>
        )}
      </Col>
      <Col
        md={3}
        className="lineRightDashboard"
        style={{ marginRight: "0px", paddingRight: "0px" }}
      >
        {rightTabPontaj ? (
          <TabsPontaje
            activeTab={activeTab}
            currentUserName={currentUser}
            setTab={setTab}
          />
        ) : (
          <TabsLucrare
            currentLucrare={currentLucrare}
            setCurrentLucrare={setCurrentLucrare}
            activeTab={activeTab}
            setTab={setTab}
            refreshLucrari={refreshLucrari}
          />
        )}
      </Col>
    </Row>
  );
};

export default FisaZilnica;
